import React, { forwardRef, useEffect, useState } from "react"

// Modules
import clsx from "clsx"
import PropTypes from "prop-types"
import { useNavigate } from "react-router-dom"

// Components
import LoadingBar from "../../../components/LoadingBar"

// Views

// Context providers / Utils
import networking from "../../../utils/Networking"

// Hooks

// Material Table
import MaterialTable from "material-table"

// Icons
import SettingsIcon from '@mui/icons-material/Settings';

// Material-UI
import { Card } from "@mui/material";
import { adaptV4Theme } from "@mui/material/styles";

import { createTheme, ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
// Styles
import "./UsersView.css"
import ChangeSettings from "./ChangeSettings"
import TableIcons from "src/components/TableIcons"

const useStyles = makeStyles(theme => ({
    root: {},
    avatar: {
        marginRight: theme.spacing(2),
    },
}))

const Results = props => {
    const classes = useStyles()
    const { className, users = [], isLoading } = props
    const [openSettings, setOpenSettings] = useState(false)
    const [selectedUsers, setSelectedUsers] = useState([])
    const [permissions, setPermissions] = useState({})

    const navigate = useNavigate()

    const openModal = data => {
        setSelectedUsers(data)
        setOpenSettings(true)
        console.log(data)
    }

    const closeModal = () => {
        setOpenSettings(false)
        setSelectedUsers([])
    }

    const goToUser = userId => {
        navigate("/app/users/" + userId)
    }

    const fetchPermissions = () => {
        networking
            .get("/permissions/")
            .then(res => {
                let permissionsData = res.data
                console.log("Permissions = ", permissionsData)
                setPermissions(permissionsData)
            })
            .catch(err => {
                console.log("Error getting permissions - ", err)
            })
    }

    const theme = createTheme(adaptV4Theme({
        overrides: {
            MuiTypography: {
                h6: {
                    fontWeight: "bolder !important",
                },
            },
        },
    }))

    useEffect(fetchPermissions, [])

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <Card className={clsx(classes.root, className)}>
                    <LoadingBar loading={isLoading} />
                    <MaterialTable
                        columns={[
                            { title: "Email", field: "email" },
                            { title: "Name  ", field: "name" },
                            { title: "Company", field: "company" },
                            { title: "Num. of Fields", field: "num_fields" },
                            { title: "Source Account", field: "source_account" },
                        ]}
                        data={users.map(user => {
                            let rowUser = user[1]
                            rowUser["user_id"] = user[0]
                            return rowUser
                        })}
                        icons={TableIcons}
                        options={{
                            selection: true,
                            pageSize: 20,
                            pageSizeOptions: [5, 10, 20, 40, 60, 80, 100],
                            emptyRowsWhenPaging: false,
                        }}
                        title="Users"
                        onRowClick={(event, rowData) => goToUser(rowData.user_id)}
                        actions={[
                            {
                                tooltip: 'Change settings',
                                icon: () => <SettingsIcon />,
                                onClick: (_, data) => openModal(data)
                            }
                        ]}
                    />
                </Card>
                <ChangeSettings
                    isOpen={openSettings}
                    closeModal={closeModal}
                    selectedUsers={selectedUsers}
                    permissions={permissions}
                />
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

Results.propTypes = {
    className: PropTypes.string,
    users: PropTypes.array.isRequired,
}

export default Results
