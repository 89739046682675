import React, { useState, useEffect, useContext } from "react"
// Modules

// Components
import Page from "src/components/Page"

// Views
import Results from "./Results"
import Toolbar from "./Toolbar"

// Context providers / Utils
import networking from "../../../utils/Networking"
import { sortObjectAlphabetically } from "../../../utils/sorting"
import EnvContext from "src/contexts/EnvContext"

// Hooks

// Material-UI *
import { Box, Container } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

// Styles

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: "100%",
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
}))

const UsersView = () => {
    const classes = useStyles()
    const [users, setUsers] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [sourceAccounts, setSourceAccounts] = useState({})
    const { env } = useContext(EnvContext)

    async function fetchData() {
        let source_accounts = await networking.get("/users/" + networking.getEnv() + "/source_accounts")
        setSourceAccounts(source_accounts.data)
    }

    async function fetchUsers() {
        setIsLoading(true)
        try {
            let mUsers = await networking.get("/users/env/users_data")
            let sortedUsers = sortObjectAlphabetically(mUsers.data, "email")
            setUsers(sortedUsers)
            setIsLoading(false)
        } catch (err) {
            console.log("Error getting users ", err)
            setIsLoading(false)
        }
    }

    function onEnvChange() {
        fetchUsers()
        fetchData()
    }

    useEffect(() => {
        fetchUsers()
        fetchData()
    }, [])

    useEffect(() => {
        onEnvChange()
    }, [env])

    return (
        <Page className={classes.root} title="Users">
            <Container maxWidth={false}>
                <Toolbar sourceAccounts={sourceAccounts} onRefresh={onEnvChange} />
                <Box mt={3}>
                    <Results users={users} isLoading={isLoading} />
                </Box>
            </Container>
        </Page>
    )
}

export default UsersView
