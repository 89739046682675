/**
 * usePrevious Hook
 *
 * This hook allows to keep track of the previous value
 * of the selected state variable.
 *
 * It works because the return triggers before the useEffect can
 * update the reference value.
 *
 * Reference: https://usehooks.com/usePrevious/
 */
import { useState, useEffect, useRef } from "react"

export function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
}
