import React from "react"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"

import DangerButton from "./DangerButton"

const ConfirmDialog = props => {
    const { title, children, open, setOpen, onConfirm, confirmText, confirmClass = "success" } = props
    return (
        <Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="confirm-dialog">
            <DialogTitle id="confirm-dialog">{title}</DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                <Button variant="text" onClick={() => setOpen(false)}>
                    Cancel
                </Button>
                <div>
                    {confirmClass === "danger" && (
                        <DangerButton
                            onClick={() => {
                                setOpen(false)
                                onConfirm()
                            }}
                            variant="contained"
                            text={confirmText}
                            id="confirmBtn"
                        />
                    )}
                    {confirmClass === "success" && (
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={() => {
                                setOpen(false)
                                onConfirm()
                            }}
                            id="confirmBtn"
                        >
                            {confirmText}
                        </Button>
                    )}
                </div>
            </DialogActions>
        </Dialog>
    )
}
export default ConfirmDialog
