import React, { useState } from "react"

// Modules

// Components

// Views

// Context providers / Utils
import networking from "../../../utils/Networking"

// Hooks

// Material-UI *
import TextField from "@mui/material/TextField"
import Grid from "@mui/material/Grid"
import Button from "@mui/material/Button"

import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import {
    capitalize,
    FormControl,
    FormControlLabel,
    FormLabel,
    Input,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import LoadingBar from "src/components/LoadingBar"
import swal from "sweetalert"

// Styles
const useStyles = makeStyles(theme => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}))

const ChangeSettings = ({ isOpen, closeModal, selectedUsers, permissions }) => {
    const classes = useStyles()
    const [permissionType, setPermissionType] = useState("views")
    const [permissionFirstLevel, setPermissionFirstLevel] = useState("")
    const [permissionSecondLevel, setPermissionSecondLevel] = useState("")
    const [value, setValue] = useState("")
    const [isLoading, setIsLoading] = useState(false)
    const handlePermissionFirstLevelChange = event => {
        setPermissionFirstLevel(event.target.value || "")
        setPermissionSecondLevel("")
        setValue("")
    }

    const handlePermissionSecondLevelChange = event => {
        setPermissionSecondLevel(event.target.value || "")
        setValue("")
    }

    const handlePermissionTypeChange = event => {
        setPermissionType(event.target.value)
        setPermissionFirstLevel("")
        setPermissionSecondLevel("")
        setValue("")
    }

    const handleValueChange = event => {
        event.preventDefault()
        setValue(event.target.value)
        console.log(event)
    }

    const handleChangePermissionsForUsers = () => {
        setIsLoading(true)
        console.log(permissionType, permissionFirstLevel, permissionSecondLevel, value, selectedUsers)
        networking
            .post("/permissions/env/", {
                permissionType: permissionType,
                permissionFirstLevel: permissionFirstLevel,
                permissionSecondLevel: permissionSecondLevel,
                selectedUsers: selectedUsers,
                value: value,
            })
            .then(res => {
                console.log("RES = ", res)
                if (res.status === 200) {
                    swal("Success!", JSON.stringify(res.data), "success")
                } else {
                    console.log("Error copying user - ", res.data)
                }
                setIsLoading(false)
            })
            .catch(err => {
                console.log("Error getting permissions - ", err)
                console.log("Error copying user - ", err)
                setIsLoading(false)
            })
    }
    return (
        <div>
            <Dialog
                open={isOpen}
                onClose={() => {
                    closeModal()
                }}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Change Settings</DialogTitle>
                <LoadingBar loading={isLoading} />
                <DialogContent style={{ overflow: "hidden" }}>
                    <DialogContentText>
                        You will change the settings for the {selectedUsers.length} selected users in{" "}
                        <b>{networking.getEnv()}</b>
                    </DialogContentText>
                    <Grid container spacing={3}>
                        <Grid item xs={6}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Permission Type</FormLabel>
                                <RadioGroup
                                    aria-label="permissionType"
                                    name="permissionType"
                                    value={permissionType}
                                    onChange={handlePermissionTypeChange}
                                >
                                    {Object.keys(permissions).length &&
                                        Object.keys(permissions).map(p => (
                                            <FormControlLabel key={p} value={p} control={<Radio />} label={p} />
                                        ))}
                                </RadioGroup>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl className={classes.formControl}>
                                <InputLabel id="permission-select-label">{capitalize(permissionType)}</InputLabel>
                                <Select
                                    label="permission-label"
                                    id="permission-select"
                                    value={permissionFirstLevel}
                                    onChange={handlePermissionFirstLevelChange}
                                    input={<Input />}
                                >
                                    {Object.keys(permissions).length &&
                                        Object.keys(permissions[permissionType]).map(p => (
                                            <MenuItem key={p} value={p}>
                                                {p}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </Grid>
                        {permissionFirstLevel ? (
                            typeof permissions[permissionType][permissionFirstLevel] === "object" ? (
                                <Grid item xs={6}>
                                    <FormControl className={classes.formControl}>
                                        <InputLabel id="permission-second-select-label">
                                            {capitalize(permissionFirstLevel)}
                                        </InputLabel>
                                        <Select
                                            label="permission-second-label"
                                            id="permission-second-select"
                                            value={permissionSecondLevel}
                                            onChange={handlePermissionSecondLevelChange}
                                            input={<Input />}
                                        >
                                            {Object.keys(permissions).length &&
                                                Object.keys(permissions[permissionType][permissionFirstLevel]).map(
                                                    p => (
                                                        <MenuItem key={p} value={p}>
                                                            {p}
                                                        </MenuItem>
                                                    )
                                                )}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            ) : (
                                <Grid item xs={6}>
                                    {typeof permissions[permissionType][permissionFirstLevel] === "boolean" ? (
                                        <FormControl component="fieldset">
                                            <FormLabel component="legend">{permissionFirstLevel}</FormLabel>
                                            <RadioGroup
                                                aria-label="permissionFirstLevel"
                                                name="permissionFirstLevel"
                                                value={value}
                                                onChange={handleValueChange}
                                            >
                                                <FormControlLabel value={"True"} control={<Radio />} label={"True"} />
                                                <FormControlLabel value={"False"} control={<Radio />} label={"False"} />
                                            </RadioGroup>
                                        </FormControl>
                                    ) : (
                                        <TextField
                                            id="text-field-permission"
                                            label="Value to set"
                                            value={value}
                                            onChange={handleValueChange}
                                            variant="outlined"
                                        />
                                    )}
                                </Grid>
                            )
                        ) : null}
                        {permissionSecondLevel && (
                            <Grid item xs={6}>
                                {typeof permissions[permissionType][permissionFirstLevel][permissionSecondLevel] ===
                                "boolean" ? (
                                    <FormControl component="fieldset">
                                        <FormLabel component="legend">{permissionSecondLevel}</FormLabel>
                                        <RadioGroup
                                            aria-label="permissionFirstLevel"
                                            name="permissionFirstLevel"
                                            value={value}
                                            onChange={handleValueChange}
                                        >
                                            <FormControlLabel value={"True"} control={<Radio />} label={"True"} />
                                            <FormControlLabel value={"False"} control={<Radio />} label={"False"} />
                                        </RadioGroup>
                                    </FormControl>
                                ) : (
                                    <TextField
                                        id="text-field-permission"
                                        label="Value to set"
                                        value={value}
                                        onChange={handleValueChange}
                                        variant="outlined"
                                    />
                                )}
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => {
                            closeModal()
                        }}
                        color="primary"
                    >
                        Cancel
                    </Button>
                    <Button type="submit" color="primary" onClick={handleChangePermissionsForUsers} disabled={!value}>
                        Change value
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ChangeSettings
