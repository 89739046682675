import { colors, createTheme } from "@mui/material";
import { adaptV4Theme } from "@mui/material/styles"
import shadows from "./shadows"
import typography from "./typography"

const theme = createTheme(adaptV4Theme({
    palette: {
        background: {
            dark: "#F4F6F8",
            default: colors.common.white,
            paper: colors.common.white,
        },
        primary: {
            light: "#5dcac2",
            main: "#1f9992",
            dark: "#006a64",
            contrastText: "#fff",
        },
        secondary: {
            light: "#ffaa5e",
            main: "#f27930",
            dark: "#b94a00",
            contrastText: "#000",
        },
        default: {
            light: "#cccccc",
            main: "#dedede",
            dark: "#ededed",
            contrastText: "#000",
        },
        text: {
            primary: colors.blueGrey[900],
            secondary: colors.blueGrey[600],
        },
    },
    shadows,
    typography,
}))

export default theme
