const local = {
    backend_server: "http://127.0.0.1:8000/api/v1/",
    firebase: {
        apiKey: "AIzaSyBL4IkJ8n_8hAcSG9bNBqaYm8wfDHjaRSI",
        authDomain: "climateai-admin-dashboard.firebaseapp.com",
        projectId: "climateai-admin-dashboard",
        storageBucket: "climateai-admin-dashboard.appspot.com",
        messagingSenderId: "715662979887",
        appId: "1:715662979887:web:0d70a5d565c01f99c927da",
        measurementId: "G-XPSE1C031L",
    },
    testing: true,
}

const develop = {
    backend_server: "https://admin-backend-api-csua3brmga-uc.a.run.app/api/v1/",
    firebase: {
        apiKey: "AIzaSyBL4IkJ8n_8hAcSG9bNBqaYm8wfDHjaRSI",
        authDomain: "climateai-admin-dashboard.firebaseapp.com",
        projectId: "climateai-admin-dashboard",
        storageBucket: "climateai-admin-dashboard.appspot.com",
        messagingSenderId: "715662979887",
        appId: "1:715662979887:web:0d70a5d565c01f99c927da",
        measurementId: "G-XPSE1C031L",
    },
    testing: false,
}

const environments = {
    local: local,
    develop: develop,
}

const config = environments[process.env.REACT_APP_STAGE]

export default {
    ...config,
}
