import React, { useEffect } from "react"
// Modules
import { useForm } from "react-hook-form"
import PropTypes from "prop-types"

// Components
// Views
// Context providers / Utils
// Hooks
// Material-UI *
import { Box, Button, Card, CardContent, CardHeader, Divider, Grid, TextField, Select, MenuItem } from "@mui/material"

// Styles

// Frontend timezone data substitute for stage and prod
const timezoneObject = {
    amsterdam: "Europe/Amsterdam",
    boise: "America/Boise",
    chicago: "America/Chicago",
    jerusalem: "Asia/Jerusalem",
    johannesburg: "Africa/Johanesburg",
    kolkate: "Asia/Kolkata",
    mexico_city: "America/Mexico_City",
    los_angeles: "America/Los_Angeles",
    rome: "Europe/Rome",
    sydeny: "Australia/Sydney",
    winnipeg: "America/Winnipeg",
}

const EditableData = props => {
    const { userData, setUserData, timezones, units, sourceAccounts } = props

    const { register, handleSubmit, errors, setValue } = useForm()

    useEffect(() => {
        setValue("name", userData?.name || "")
        setValue("email", userData?.email || "")
        setValue("company", userData?.company || "")
        setValue("timezone", userData?.timezone)
        setValue("unit", userData?.unit)
        setValue("source_account", userData?.source_account)
    }, [userData])

    return (
        <form
            id="updateUser"
            onSubmit={handleSubmit(() => {
                /*Required to avoid page refresh*/
            })}
        >
            <Card>
                <CardHeader subheader="The information can be edited" title="Profile" />
                <Divider />
                <CardContent>
                    <Grid container spacing={3}>
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                placeholder="Name"
                                name="name"
                                inputRef={register({ required: "* The name is required" })}
                                variant="outlined"
                                id="editable-name"
                                onChange={e => {
                                    setUserData(e.nativeEvent.target.value, "name")
                                }}
                            />
                            {errors.name && <p className="error-message">{errors.name.message}</p>}
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                placeholder="example@email.com"
                                type="email"
                                name="email"
                                inputRef={register({ required: "* The email is required" })}
                                variant="outlined"
                                id="editable-email"
                                onChange={e => {
                                    setUserData(e.nativeEvent.target.value, "email")
                                }}
                            />
                            {errors.email && <p className="error-message">{errors.email.message}</p>}
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                fullWidth
                                placeholder="Company"
                                name="company"
                                inputRef={register({ required: "* The company name is required" })}
                                variant="outlined"
                                id="editable-company"
                                onChange={e => {
                                    setUserData(e.nativeEvent.target.value, "company")
                                }}
                            />
                            {errors.company && <p className="error-message">{errors.company.message}</p>}
                        </Grid>
                        <Grid item md={6} xs={12}>
                            {timezones && Object.keys(timezones).length > 0 && userData && userData.timezone ? (
                                <>
                                    <Select
                                        fullWidth
                                        name="timezone"
                                        variant="outlined"
                                        id="editable-timezone"
                                        value={userData.timezone}
                                        onChange={e => {
                                            setUserData(e.target.value, "timezone")
                                        }}
                                    >
                                        {Object.keys(timezones).map(key => {
                                            return (
                                                <MenuItem key={key} value={timezones[key]}>
                                                    {timezones[key]}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                    {errors.timezone && <p className="error-message">{errors.timezone.message}</p>}
                                </>
                            ) : (
                                <>
                                    <Select
                                        fullWidth
                                        name="timezone"
                                        variant="outlined"
                                        id="editable-timezone"
                                        value={userData.timezone ? userData.timezone : timezones}
                                        onChange={e => {
                                            setUserData(e.target.value, "timezone")
                                        }}
                                    >
                                        {Object.keys(timezoneObject).map(key => {
                                            return (
                                                <MenuItem key={key} value={timezoneObject[key]}>
                                                    {timezoneObject[key]}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                    {errors.timezone && <p className="error-message">{errors.timezone.message}</p>}
                                </>
                            )}
                        </Grid>
                        <Grid item md={6} xs={12}>
                            {units && Object.keys(units).length > 0 && userData && userData.unit ? (
                                <>
                                    <Select
                                        fullWidth
                                        name="unit"
                                        variant="outlined"
                                        id="editable-unit"
                                        value={userData.unit}
                                        onChange={e => {
                                            setUserData(e.target.value, "unit")
                                        }}
                                    >
                                        {Object.keys(units).map(key => {
                                            return (
                                                <MenuItem key={key} value={units[key]}>
                                                    {units[key]}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                    {errors.unit && <p className="error-message">{errors.unit.message}</p>}
                                </>
                            ) : (
                                <>
                                    <TextField
                                        fullWidth
                                        placeholder="Unit system"
                                        name="unit"
                                        inputRef={register({ required: "* The unit system is required" })}
                                        variant="outlined"
                                        id="editable-unit"
                                        onChange={e => {
                                            setUserData(e.nativeEvent.target.value, "unit")
                                        }}
                                    />
                                    {errors.unit && <p className="error-message">{errors.unit.message}</p>}
                                </>
                            )}
                        </Grid>
                        <Grid item md={6} xs={12}>
                            {sourceAccounts &&
                            Object.keys(sourceAccounts).length > 0 &&
                            userData &&
                            userData.source_account ? (
                                <>
                                    <Select
                                        fullWidth
                                        name="source_account"
                                        variant="outlined"
                                        id="editable-source_account"
                                        value={userData.source_account}
                                        onChange={e => {
                                            setUserData(e.target.value, "source_account")
                                        }}
                                    >
                                        {Object.keys(sourceAccounts).map(key => {
                                            return (
                                                <MenuItem key={key} value={key}>
                                                    {sourceAccounts[key]}
                                                </MenuItem>
                                            )
                                        })}
                                    </Select>
                                    {errors.source_account && (
                                        <p className="error-message">{errors.source_account.message}</p>
                                    )}
                                </>
                            ) : (
                                <>
                                    <TextField
                                        fullWidth
                                        placeholder="Source Account"
                                        name="source_account"
                                        inputRef={register({ required: "* The source account is required" })}
                                        variant="outlined"
                                        id="editable-source_account"
                                        onChange={e => {
                                            setUserData(e.nativeEvent.target.value, "source_account")
                                        }}
                                    />
                                    {errors.source_account && (
                                        <p className="error-message">{errors.source_account.message}</p>
                                    )}
                                </>
                            )}
                        </Grid>
                    </Grid>
                </CardContent>
                <Box display="none" justifyContent="flex-end" p={2}>
                    <Button
                        type="submit"
                        form="updateUser"
                        color="primary"
                        variant="contained"
                        id="editableData-submitBtn"
                    >
                        Save
                    </Button>
                </Box>
            </Card>
        </form>
    )
}

EditableData.propTypes = {
    className: PropTypes.string,
}

export default EditableData
