function sortObjectAlphabetically(objectToSort, propertyToCompare) {
    let arrayToSort = Object.entries(objectToSort)
    let arr = arrayToSort.slice(0)

    let sorted = arr.sort(function(a, b) {
        var textA = a[1][propertyToCompare].toUpperCase()
        var textB = b[1][propertyToCompare].toUpperCase()
        return textA < textB ? -1 : textA > textB ? 1 : 0
    })
    return sorted
}

export { sortObjectAlphabetically }
