import React from "react"
// Modules
// Components
import Page from "src/components/Page"
// Views
import Features from "./Features"
import Views from "./Views"
// Context providers / Utils
// Hooks
// Material-UI *
import { Container, Grid } from "@mui/material"
// Styles

const PermissionsView = props => {
    const { userPermissions, setUserPermissions, userEmails, handleOpenUserEmailDialog, deleteUserEmail } = props
    return (
        <Page>
            <Container maxWidth="lg">
                <Grid container spacing={2} style={{ marginTop: 25 }}>
                    <Grid container spacing={2}>
                        <Grid item md={8} xs={12}>
                            <Features
                                userFeatures={userPermissions?.features}
                                setUserFeatures={data => setUserPermissions(data, "features")}
                                userEmails={userEmails}
                                handleOpenUserEmailDialog={handleOpenUserEmailDialog}
                                deleteUserEmail={deleteUserEmail}
                            />
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <Views
                                userViews={userPermissions?.views}
                                setUserViews={data => setUserPermissions(data, "views")}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    )
}

export default PermissionsView
