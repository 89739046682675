import React from "react"
// Modules
import PropTypes from "prop-types"
import clsx from "clsx"

// Components
// Views
// Context providers / Utils
// Hooks
// Material-UI *
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    Divider,
    FormControlLabel,
    Grid,
    Typography,
    TextField,
    Table,
    TableHead,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Paper,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
// Styles

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: "100%",
    },
    item: {
        display: "flex",
        flexDirection: "column",
    },
}))

const Features = props => {
    const classes = useStyles()
    const {
        userFeatures,
        setUserFeatures,
        userEmails,
        className,
        onSaveFeatures,
        handleOpenUserEmailDialog,
        deleteUserEmail,
        ...rest
    } = props

    function handleInputChange(featureName, propName, e, isNumber = false) {
        let val = isNumber ? parseFloat(e.target.value) : e.target.value
        setUserFeatures({ ...userFeatures, [featureName]: { ...userFeatures[featureName], [propName]: val } })
    }

    function handleCheckboxChange(featureName, propName, e) {
        setUserFeatures({
            ...userFeatures,
            [featureName]: { ...userFeatures[featureName], [propName]: e.target.checked },
        })
    }

    return (
        <form className={clsx(classes.root, className)} {...rest}>
            <Card>
                <CardHeader subheader="Permissions" title="Features" />
                <Divider />
                <CardContent>
                    <Grid container spacing={3} wrap="wrap">
                        {Object.keys(userFeatures).map(kFeat => {
                            let featObj = userFeatures[kFeat]
                            return (
                                <Grid
                                    key={"alert-permission-container-" + kFeat}
                                    className={classes.item}
                                    item
                                    md={3}
                                    sm={6}
                                    xs={6}
                                >
                                    <Typography color="textPrimary" gutterBottom variant="h6">
                                        {kFeat.replace("_", " ")}
                                    </Typography>
                                    {Object.entries(featObj).map(([key, v]) => {
                                        let value
                                        if (userFeatures[kFeat] && userFeatures[kFeat].hasOwnProperty(key)) {
                                            value = userFeatures[kFeat][key]
                                        }
                                        if (typeof v === "string") {
                                            return (
                                                <FormControlLabel
                                                    key={"feature-permission-" + kFeat + "-" + key}
                                                    control={
                                                        <TextField
                                                            className={classes.padLeft}
                                                            placeholder={key}
                                                            helperText={key}
                                                            value={value || ""}
                                                            onChange={e => {
                                                                handleInputChange(kFeat, key, e)
                                                            }}
                                                        />
                                                    }
                                                />
                                            )
                                        } else if (typeof v === "boolean") {
                                            return (
                                                <FormControlLabel
                                                    key={"feature-permission-" + kFeat + "-" + key}
                                                    control={<Checkbox checked={value || false} />}
                                                    label={key}
                                                    onChange={e => {
                                                        handleCheckboxChange(kFeat, key, e)
                                                    }}
                                                />
                                            )
                                        } else if (typeof v === "number") {
                                            return (
                                                <FormControlLabel
                                                    key={"feature-permission-" + kFeat + "-" + key}
                                                    control={
                                                        <TextField
                                                            className={classes.padLeft}
                                                            placeholder={key}
                                                            helperText={key}
                                                            type="number"
                                                            value={value || ""}
                                                            onChange={e => {
                                                                handleInputChange(kFeat, key, e, true)
                                                            }}
                                                        />
                                                    }
                                                />
                                            )
                                        } else {
                                            return <></>
                                        }
                                    })}
                                </Grid>
                            )
                        })}
                    </Grid>
                </CardContent>
                <CardContent>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {userEmails.map((userEmail, idx) => (
                                    <TableRow key={`row-${idx}`}>
                                        <TableCell>{userEmail.name}</TableCell>
                                        <TableCell>{userEmail.email}</TableCell>
                                        <TableCell>
                                            <Button onClick={() => deleteUserEmail(idx)} color="secondary">
                                                Delete
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box display="flex" justifyContent="flex-end" p={3}>
                        <Button color="secondary" variant="contained" onClick={handleOpenUserEmailDialog}>
                            New Email
                        </Button>
                    </Box>
                </CardContent>
            </Card>
        </form>
    )
}

Features.propTypes = {
    className: PropTypes.string,
}

export default Features
