import React, { useState } from "react"
import { Outlet } from "react-router-dom"
import makeStyles from '@mui/styles/makeStyles';
import EnvContext from "src/contexts/EnvContext"
import NavBar from "./NavBar"
import TopBar from "./TopBar"

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        display: "flex",
        height: "100%",
        overflow: "hidden",
        width: "100%",
    },
    wrapper: {
        display: "flex",
        flex: "1 1 auto",
        overflow: "hidden",
        paddingTop: 64,
        [theme.breakpoints.up("lg")]: {
            paddingLeft: 256,
        },
    },
    contentContainer: {
        display: "flex",
        flex: "1 1 auto",
        overflow: "hidden",
    },
    content: {
        flex: "1 1 auto",
        height: "100%",
        overflow: "auto",
    },
}))

const DashboardLayout = () => {
    const classes = useStyles()
    const [isMobileNavOpen, setMobileNavOpen] = useState(false)
    const [env, setEnv] = useState("production")    

    return (
        <EnvContext.Provider value={{ env }}>
            <div className={classes.root}>
                <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} env={env} setEnv={(e) => setEnv(e)} />
                <NavBar onMobileClose={() => setMobileNavOpen(false)} openMobile={isMobileNavOpen} />
                <div className={classes.wrapper}>
                    <div className={classes.contentContainer}>
                        <div className={classes.content}>
                            <Outlet context={env} />
                        </div>
                    </div>
                </div>
            </div>
        </EnvContext.Provider>
    )
}

export default DashboardLayout
