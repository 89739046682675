import React, { useState, useEffect } from 'react'

import { Button, TextField, Menu, MenuItem } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

// Styles
const useStyles = makeStyles({
    root: {
        padding: "32px 18px",
        paddingTop: 0,
        width: "100%",
    },
    button: {
        margin: "15px",
        height: 50,
    },
    cardContent: {
        display: "flex",
        alignItems: "center",
        gap: 20,
    },
})

function FieldEditionItem({ field, crops, handleFieldEdit, handleFieldDelete }) {
    const [isEditable, setEditable] = useState(false)
    const [editedField, setEditedField] = useState(field)
    const [anchorElCrop, setAnchorElCrop] = useState(null)
    const [anchorElVariety, setAnchorElVariety] = useState(null)
    const [buttonDisabled, setButtonDisabled] = useState(false)

    const classes = useStyles()

    let _field = field

    let _varieties = crops.filter(crop => crop.id === editedField.crop_id)[0]?.varieties
    let varietiesArray = _varieties ? Object.keys(_varieties).map(key => _varieties[key]) : []

    const openCropMenu = Boolean(anchorElCrop)
    const openVarietyMenu = Boolean(anchorElVariety)

    function handleMenuOptionsOpen(e, type) {
        if (isEditable && type === "crop") setAnchorElCrop(e.currentTarget)
        if (isEditable && type === "variety") setAnchorElVariety(e.currentTarget)
    }

    function handleInputChange (propName, e) {
        let targetText = e.target.value
        setEditedField({...editedField, [propName] : targetText})
    }

    function handleMenuItemSelection (propName, e) {
        let value = e.target.attributes.value.value
        let id = parseInt(e.target.attributes.id.value)
        setEditedField({ ...editedField, [propName]: value, [`${propName}_id`]: id })
    }

    function handleEditButton() {
        setEditable(false)
        handleFieldEdit(editedField)
    }
    
    function handleCancelButton() {
        setEditable(false)
        setEditedField(_field)
    }

    function handleDeleteButton() {
        handleFieldDelete(editedField)
    }

    function checkFields() {
        if ("name" in editedField  && "crop" in editedField && "variety" in editedField 
        && "region" in editedField && editedField["name"] !== "" && editedField["crop"] !== "" 
        && editedField["variety"] !== ""  && editedField["region"] !== "") {
            setButtonDisabled(false)
        } else {
            setButtonDisabled(true)
        }
    }

    useEffect(() => {
        _field = field
        setEditedField(field)
    }, [field])

    useEffect(() => {
        checkFields()
    }, [editedField])

    return (
        <div style={{ display: "flex", gap: 20 }}>
            <div style={{ display: "flex", flexDirection: "column", justifyContent: "start", gap: 20, padding: "15px 0", width: "80%" }}>
                <div style={{ display: "flex", justifyContent: "space-between", gap: 15, width: "100%" }}>
                    <TextField 
                        type="text" 
                        label='Field'
                        variant="outlined" 
                        InputLabelProps={{shrink: true}} 
                        value={editedField.name}
                        disabled={!isEditable}
                        onChange={e => handleInputChange("name", e)}
                    />
                    <TextField 
                        type="text"
                        label='Crop' 
                        variant="outlined" 
                        InputLabelProps={{shrink: true}}
                        value={editedField.crop}
                        disabled={!isEditable}
                        onClick={e => handleMenuOptionsOpen(e, "crop")}
                        InputProps={{
                            readOnly: true,
                        }}
                    />
                        <Menu
                            anchorEl={anchorElCrop}
                            open={openCropMenu}
                            onClose={() => setAnchorElCrop(null)}
                            style={{  marginTop: 50, maxHeight: 300 }}
                        >
                            {crops.map(crop => (
                                <MenuItem
                                    key={`field-creation-crop-${crop.id}`}
                                    id={crop.id}
                                    value={crop.name}
                                    style={{ width: 250 }}
                                    onClick={(e) => handleMenuItemSelection("crop", e) }
                                >
                                    {crop.name}
                                </MenuItem>
                            ))}
                        </Menu>
                    
                </div>
                <div style={{ display: "flex", justifyContent: "space-between", gap: 15, width: "100%" }}>
                    <TextField 
                            type="text" 
                            label='Variety' 
                            variant="outlined" 
                            InputLabelProps={{shrink: true}}
                            value={editedField.variety}
                            disabled={!isEditable && editedField.crop !== ""}
                            onClick={e => handleMenuOptionsOpen(e, "variety")}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    <Menu
                        anchorEl={anchorElVariety}
                        open={openVarietyMenu}
                        onClose={() => setAnchorElVariety(null)}
                        style={{  marginTop: 50, maxHeight: 300 }}
                    >
                        {varietiesArray.map(variety => (
                            <MenuItem
                                key={`field-creation-variety-${variety.id}`}
                                id={variety.id}
                                value={variety.name}
                                style={{ width: 250 }}
                                onClick={(e) => handleMenuItemSelection("variety", e) }
                            >
                                {variety.name}
                            </MenuItem>
                        ))}
                    </Menu>
                    <TextField 
                        type="text" 
                        label='Region' 
                        variant="outlined" 
                        InputLabelProps={{shrink: true}}
                        value={editedField.region}
                        disabled={!isEditable}
                        onChange={e => handleInputChange("region", e)}
                    />
                    <TextField 
                        type="date" 
                        label='Planting Date' 
                        variant="outlined" 
                        InputLabelProps={{shrink: true}}
                        value={editedField.plantingDateP}
                        disabled={!isEditable}
                        onChange={e => handleInputChange("plantingDateP", e)}
                    />
                </div>
            </div>
            {isEditable ? (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Button 
                            className={classes.button} 
                            color="primary" 
                            variant="contained"
                            disabled={buttonDisabled}
                            onClick={() => handleEditButton()}
                        >
                            Save
                        </Button>
                        <Button 
                            className={classes.button} 
                            color="primary" 
                            variant="outlined"
                            onClick={() => handleCancelButton()}
                        >
                            Cancel
                        </Button>
                    </div>
                ) : (
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Button 
                            className={classes.button} 
                            color="primary" 
                            variant="contained"
                            onClick={() => setEditable(true)}
                        >
                            Edit
                        </Button>
                        <Button 
                            className={classes.button} 
                            color="secondary" 
                            variant="contained"
                            onClick={() => handleDeleteButton()}
                        >
                            Delete
                        </Button>
                    </div>
                )}
        </div>
    )
}

export default FieldEditionItem