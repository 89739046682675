import React, { useMemo } from 'react'

import { 
    CardContent,
    Button,
    Select,
    MenuItem,
    FormHelperText,
    ListSubheader,
    Grid,
    TextField,
    Autocomplete
} from '@mui/material'

import { usePrevious } from "../../../utils/hooks"
import { useFlatUsers } from "./hooks"


function MirrorUsers(props) {
    const { 
        sourceEnv,
        onSourceEnvChange,
        getUsers,
        sourceUsers = null,
        selectedSourceUser,
        selectedDestinationUser,
        onSourceUserChange,
        onDestinationUserChange,
        onMirrorData,
        blockUpload
    } = props
    // Save the previous environment value
    const prevEnv = usePrevious(sourceEnv)
    // TODO: Find a way to extract this function to avoid code duplication
    // Instances:
    //  - src/views/tools/ClimateMirrorView/MirrorUsers.js
    //  - src/views/tools/ClimateMirrorView/MoveUsers.js
    const _getUsers = () => {
        if (prevEnv === sourceEnv && sourceUsers) return
        getUsers()
    }
    // Wrapper memo to empty the user list when the environment is changed
    const _sourceUsers = useMemo(() => prevEnv === sourceEnv ? sourceUsers : null, [sourceEnv, sourceUsers])
    // Memoized flattened user list
    const userList = useFlatUsers(_sourceUsers)

    /* RENDER FUNCTIONS */
    const firstStep = () => (
        <Grid container item sm={3}>
            <Grid item xs={1}>
                <span>❶</span>
            </Grid>
            <Grid item xs={11}>
                <Grid item xs={11}>
                    <Select style={{minWidth: "100%"}}value={sourceEnv} onChange={onSourceEnvChange}>
                        <MenuItem value={"dev"}>Development</MenuItem>
                        <MenuItem value={"staging"}>Staging</MenuItem>
                        <MenuItem value={"production"}>Production</MenuItem>
                    </Select>
                </Grid>
                <Grid item xs={12}>
                    <FormHelperText>Select source environment</FormHelperText>
                </Grid>
            </Grid>
        </Grid>
    )
    const secondStep = () => (
        <Grid container item sm={4}>
            <Grid item xs={1}>
                <span>❷</span>
            </Grid>
            <Grid container item xs={11}>
                <Grid item xs={12}>
                    <Autocomplete
                        onOpen={_getUsers}
                        options={userList}
                        groupBy={(option) => option.parent}
                        getOptionLabel={(option) => option.email}
                        onChange={onSourceUserChange}
                        isOptionEqualToValue={(option, value) => option.email === value.email}
                        renderInput={
                            (params) => <TextField {...params} label="Source User" />
                        }
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormHelperText>Select source user</FormHelperText>
                </Grid>
            </Grid>
        </Grid>
    )
    const thirdStep = () => (
        <Grid container item sm={5}>
            <Grid item xs={1}>
                <span>❸</span>
            </Grid>
            <Grid container item xs={11} columnSpacing={1}>
                <Grid item xs={8}>
                    <Autocomplete
                        onOpen={_getUsers}
                        options={userList}
                        groupBy={(option) => option.parent}
                        getOptionLabel={(option) => option.email}
                        onChange={onDestinationUserChange}
                        isOptionEqualToValue={(option, value) => option.email === value.email}
                        renderInput={
                            (params) => <TextField {...params} label="Destination User" />
                        }
                    />
                </Grid>
                <Grid item xs={4}>
                    <Button
                        style={{minHeight: "100%"}}
                        variant="contained"
                        color="primary"
                        disabled={blockUpload}
                        onClick={onMirrorData}
                    >
                        Mirror Data
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <FormHelperText>Select destination user</FormHelperText>
                </Grid>
            </Grid>
        </Grid>
    )
    return (
            <CardContent style={{ display: "flex", justifyContent: "space-between" }}>
                <Grid container columnSpacing={4}>
                    {firstStep()}
                    {secondStep()}
                    {thirdStep()}
                </Grid>
            </CardContent>
    )
}

export default MirrorUsers
