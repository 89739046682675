import React, { useMemo } from 'react'
import { usePrevious } from "../../../utils/hooks"
import { useFlatUsers } from "./hooks"

import { 
    Autocomplete,
    CardContent,
    Button,
    Select,
    MenuItem,
    FormHelperText,
    Grid,
    FormControlLabel,
    Checkbox,
    TextField
} from '@mui/material'

function MoveUsers(props) {
    const { 
        sourceEnv,
        destinationEnv,
        onSourceEnvChange,
        onDestinationEnvChange,
        getUsers,
        sourceUsers = null,
        selectedSourceUser,
        onSourceUserChange,
        dataTypes,
        onDataTypeChange = () => null,
        views,
        onAllViewsChange,
        onViewChange,
        isAllViewsChecked,
        onMoveData,
        blockUpload
    } = props
    // Save the previous environment value
    const prevEnv = usePrevious(sourceEnv)
    // Wrapper function to only get users if it is a new environment
    // TODO: Find a way to extract this function to avoid code duplication
    // Instances:
    //  - src/views/tools/ClimateMirrorView/MirrorUsers.js
    //  - src/views/tools/ClimateMirrorView/MoveUsers.js
    const _getUsers = () => {
        if (prevEnv === sourceEnv && sourceUsers) return
        getUsers()
    }
    // Wrapper memo to empty the user list when the environment is changed
    const _sourceUsers = useMemo(() => prevEnv === sourceEnv ? sourceUsers : null, [sourceEnv, sourceUsers])
    // Memoized flattened user list
    const userList = useFlatUsers(_sourceUsers)

    /* RENDER FUNCTIONS */
    const firstStep = () => (
        <Grid container item sm={4}>
            <Grid container item xs={1}>
                <span>❶</span>
            </Grid>
            <Grid container item xs={11} alignContent="flex-start" spacing={1}>
                <Grid item xs={12}>
                    <Select
                        style={{width: "100%"}}
                        value={sourceEnv}
                        onChange={onSourceEnvChange}>
                        <MenuItem value={"dev"}>Development</MenuItem>
                        <MenuItem value={"staging"}>Staging</MenuItem>
                        <MenuItem value={"production"}>Production</MenuItem>
                    </Select>
                    <FormHelperText>Select source environment</FormHelperText>
                </Grid>
                <Grid item xs={12}>
                    <Autocomplete
                        onOpen={_getUsers}
                        id="user-select"
                        options={userList}
                        groupBy={(option) => option.parent}
                        getOptionLabel={(option) => option.email}
                        onChange={onSourceUserChange}
                        isOptionEqualToValue={(option, value) => option.email === value.email}
                        renderInput={(params) => <TextField {...params} label="User" />}
                    />
                    <FormHelperText>Select source user</FormHelperText>
                </Grid>
            </Grid>
        </Grid>
    )
    const secondStep = () => (
        <Grid
            sm={4}
            item
            container>
            <Grid container item xs={1}>
                <span>❷</span>
            </Grid>
            <Grid xs={11}
                item
                direction="column"
                container>
                <Grid item>
                    <FormControlLabel 
                        control={
                            <Checkbox
                                name="database"
                                onChange={() => onDataTypeChange("Database")}
                                checked={dataTypes.includes("Database")}
                            >
                            </Checkbox>
                        }
                        label="Database"
                    />
                </Grid>
                <Grid item>
                    <FormControlLabel 
                        control={
                            <Checkbox
                                name="csv"
                                onChange={() => onDataTypeChange("CSVs")}
                                checked={dataTypes.includes("CSVs")}
                            >
                            </Checkbox>
                        }
                        label="CSVs"
                    />
                </Grid>
                <Grid item>
                    <FormControlLabel
                        control={
                            <Checkbox
                                name="cache"
                                onChange={() => onDataTypeChange("Cache")}
                                checked={dataTypes.includes("Cache")}
                            >
                            </Checkbox>
                        }
                        label="Cache"
                    />
                </Grid>
                {dataTypes.includes("Cache") &&
                <Grid container item columnSpacing={8} style={{paddingLeft: "1.5em"}}>
                    <Grid item xs={6}>
                        <FormControlLabel
                            value="All"
                            control={<Checkbox onClick={onAllViewsChange} checked={isAllViewsChecked} />}
                            label="All"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            value="Overview"
                            control={<Checkbox onClick={onViewChange} checked={views.includes("Overview")} />}
                            label="Overview"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            value="Decadal Risk"
                            control={<Checkbox onClick={onViewChange} checked={views.includes("Decadal Risk")} />}
                            label="Decadal Risk"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            value="Decadal Var"
                            control={<Checkbox onClick={onViewChange} checked={views.includes("Decadal Var")} />}
                            label="Decadal Var"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            value="Seasonal Risk"
                            control={<Checkbox onClick={onViewChange} checked={views.includes("Seasonal Risk")} />}
                            label="Seasonal Risk"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            value="Seasonal Var"
                            control={<Checkbox onClick={onViewChange} checked={views.includes("Seasonal Var")} />}
                            label="Seasonal Var"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            value="Departure"
                            control={<Checkbox onClick={onViewChange} checked={views.includes("Departure")} />}
                            label="Departure"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            value="Portfolio"
                            control={<Checkbox onClick={onViewChange} checked={views.includes("Portfolio")} />}
                            label="Portfolio"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel
                            value="Validation"
                            control={<Checkbox onClick={onViewChange} checked={views.includes("Validation")} />}
                            label="Validation"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <FormControlLabel 
                            value="Analogs"
                            control={<Checkbox onClick={onViewChange} checked={views.includes("Analogs")} />}
                            label="Analogs"
                        />
                    </Grid>
                </Grid>
                }
                <div style={{ marginLeft: "2em", display: "flex", flexDirection: "column", width: 600 }}>
                        {dataTypes.includes("Cache") && (
                            <div style={{ display: "flex", justifyContent: "space-between", flexWrap: "wrap" }}></div>
                        )}
                </div>
            </Grid>
        </Grid>
    )
    const thirdStep = () => (
        <Grid container item sm={4}>
            <Grid container item xs={1}>
                <span>❸</span>
            </Grid>
            <Grid container item direction="column" xs={11} spacing={1}>
                <Grid container item justifyContent="space-between">
                    <Select
                        multiple
                        value={destinationEnv}
                        onChange={onDestinationEnvChange}
                        style={{minWidth: "12em"}}>
                        <MenuItem value="dev">Development</MenuItem>
                        <MenuItem value="staging">Staging</MenuItem>
                        <MenuItem value="production">Production</MenuItem>
                    </Select>
                    <FormHelperText>Select destination environment(s)</FormHelperText>
                </Grid>
                <Grid item>
                    <Button
                        variant="contained"
                        color="primary"
                        disabled={blockUpload}
                        onClick={onMoveData}
                    >
                        Move Data
                    </Button>
                </Grid>
            </Grid>
        </Grid>
    )
    return (
            <CardContent style={{ display: "flex", gap: "20px" }}>
                <Grid container spacing={5} justifyContent="space-around">
                    {firstStep()}
                    {secondStep()}
                    {thirdStep()}
                </Grid>
            </CardContent>
    )
}

export default MoveUsers
