import React, { useEffect, useState } from "react"

// Modules
import { useForm } from "react-hook-form"

// Components

// Views

// Context providers / Utils
import networking from "../../../utils/Networking"

// Hooks

// Material-UI *
import TextField from "@mui/material/TextField"
import Grid from "@mui/material/Grid"
import Button from "@mui/material/Button"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import FormHelperText from "@mui/material/FormHelperText"

import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"

// Styles

const AddUser = props => {
    const { isOpen, closeModal, onAddUser, formError, sourceAccounts } = props
    const [selectedSourceAccount, setSelectedSourceAccount] = useState("this")
    const { register, handleSubmit, errors } = useForm()

    const onSubmit = _data => {
        const data = { ..._data, source_account: selectedSourceAccount }
        onAddUser(data)
    }

    useEffect(() => {
        setSelectedSourceAccount("this")
    }, [sourceAccounts])

    return (
        <div>
            <Dialog
                open={isOpen}
                onClose={() => {
                    closeModal()
                }}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Add user</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        The new user will be added to <b>{networking.getEnv()}</b>
                    </DialogContentText>
                    <form id="addUserForm" className="add-user-form" onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={3}>
                            <Grid item xs={6}>
                                <TextField
                                    type="text"
                                    placeholder="email@example.com"
                                    name="email"
                                    inputRef={register({
                                        required: "* The email address is required",
                                    })}
                                    id="email"
                                />
                                {errors.email && <p className="error-message">{errors.email.message}</p>}
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    type="password"
                                    placeholder="Password"
                                    name="password"
                                    inputRef={register({
                                        required: "* The password is required",
                                        minLength: {
                                            value: 8,
                                            message: "The password must be at least 8 characters long",
                                        },
                                    })}
                                    id="password"
                                />
                                {errors.password && <p className="error-message">{errors.password.message}</p>}
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    type="text"
                                    placeholder="Company"
                                    name="company"
                                    inputRef={register({ required: "* The company name is required" })}
                                    id="company"
                                />
                                {errors.company && <p className="error-message">{errors.company.message}</p>}
                            </Grid>
                            <Grid item xs={6}>
                                <TextField type="text" placeholder="Name" name="name" inputRef={register} id="name" />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    type="text"
                                    placeholder="Display name"
                                    name="display_name"
                                    inputRef={register({ required: "* The display name is required" })}
                                    id="display_name"
                                />
                                {errors.display_name && <p className="error-message">{errors.display_name.message}</p>}
                            </Grid>
                            <Grid item xs={6}>
                                <Select
                                    fullWidth
                                    name="source_account"
                                    variant="outlined"
                                    placeholder="Source account (optional)"
                                    id="editable-source_account"
                                    value={selectedSourceAccount}
                                    onChange={e => {
                                        setSelectedSourceAccount(e.target.value)
                                    }}
                                >
                                    {Object.keys(sourceAccounts).map(key => {
                                        return (
                                            <MenuItem key={key} value={key}>
                                                {sourceAccounts[key]}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                                <FormHelperText>Source Account</FormHelperText>
                                {errors.source_account && (
                                    <p className="error-message">{errors.source_account.message}</p>
                                )}
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <p className="error-message bigger">{formError}</p>
                <DialogActions>
                    <Button
                        onClick={() => {
                            closeModal()
                        }}
                        color="primary"
                    >
                        Cancel
                    </Button>
                    <Button type="submit" form="addUserForm" color="primary" id="submitBtn">
                        Add user
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default AddUser
