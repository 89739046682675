import React, { useState } from "react"

// Modules
import { Link as RouterLink } from "react-router-dom"
import clsx from "clsx"
import PropTypes from "prop-types"

// Components
import Logo from "src/components/Logo"

// Views
// Context providers / Utils
import app from "../../utils/Fire"
import networking from "../../utils/Networking"

// Hooks

// Material-UI *
import { AppBar, Box, Grid, Hidden, IconButton, Toolbar } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import MenuIcon from "@mui/icons-material/Menu"
import InputIcon from "@mui/icons-material/Input"
import MenuItem from "@mui/material/MenuItem"
import Select from "@mui/material/Select"
import InputLabel from "@mui/material/InputLabel"

// Styles
import "./topbar.css"

const useStyles = makeStyles(() => ({
    root: {},
    avatar: {
        width: 60,
        height: 60,
    },
}))

const TopBar = ({ className, onMobileNavOpen, env, setEnv, ...rest }) => {
    const classes = useStyles()


    const handleChange = event => {
        networking.setEnvTo(event.target.value)
        setEnv(event.target.value)
    }

    function handleLogout(e) {
        e.preventDefault()
        app.auth().signOut()
    }

    return (
        <AppBar className={clsx(classes.root, className)} elevation={0} {...rest}>
            <Toolbar>
                <RouterLink to="/">
                    <Logo />
                </RouterLink>
                <Box flexGrow={1}>
                    <Grid container justifyContent="center" alignItems="center" spacing={1}>
                        <Grid container className="env-container" direction="row" justifyContent="center" alignItems="center">
                            <InputLabel className="select-label" id="env-select-label">
                                Environment -&nbsp;
                            </InputLabel>
                            <Select
                                variant="standard"
                                labelId="env-select-label"
                                id="env-select"
                                value={env}
                                onChange={handleChange}>
                                <MenuItem value={"dev"}>Development</MenuItem>
                                <MenuItem value={"staging"}>Staging</MenuItem>
                                <MenuItem value={"production"}>Production</MenuItem>
                            </Select>
                        </Grid>
                    </Grid>
                </Box>

                <IconButton id="logoutBtn" color="inherit" onClick={handleLogout} size="large">
                    <InputIcon />
                </IconButton>
                <Hidden lgUp>
                    <IconButton color="inherit" onClick={onMobileNavOpen} size="large">
                        <MenuIcon />
                    </IconButton>
                </Hidden>
            </Toolbar>
        </AppBar>
    );
}

TopBar.propTypes = {
    className: PropTypes.string,
    onMobileNavOpen: PropTypes.func,
}

export default TopBar
