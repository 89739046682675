import React, { useState, useEffect } from "react"
// Modules

// Components
import ConfirmDialog from "../../../components/ConfirmDialog"
import LoadingBar from "../../../components/LoadingBar"

// Views

// Context providers / Utils

// Hooks

// Material-UI *
import {
    Button,
    Grid,
    CardHeader,
    Divider,
    Card,
    CardContent,
    Typography,
    Box,
    Checkbox,
    FormControlLabel,
    Radio,
    RadioGroup,
} from "@mui/material"
import FaceIcon from "@mui/icons-material/Face"
import BusinessIcon from "@mui/icons-material/Business"
import LandscapeIcon from "@mui/icons-material/Landscape"

// Styles
import "./UserSelection.css"

const UserCopySummary = props => {
    const { selectedUsers, handleUsersCopy, isLoading } = props
    const [openConfirmModal, setOpenConfirmModal] = useState(false)
    const [isDataSelectedToCopy, setIsDataSelectedToCopy] = useState(false)
    const [dataToCopy, setDataToCopy] = useState({
        permissions: "false",
        alerts: "copy",
        fields: "false",
    })

    function handleDataToCopyChange(propName, value) {
        setDataToCopy({ ...dataToCopy, [propName]: value.toString() })
    }

    function handleModeToCopyChange(propName, value) {
        setDataToCopy({ ...dataToCopy, [propName]: value })
    }

    function checkForDataSelected() {
        for (const [key, value] of Object.entries(dataToCopy)) {
            if (key === "permissions" && value !== "false") {
                return true
            }
            if (value !== "true" && value !== "false") return true
        }
        return false
    }

    useEffect(() => {
        setIsDataSelectedToCopy(checkForDataSelected())
    }, [dataToCopy])

    return (
        <Card>
            <LoadingBar loading={isLoading} />
            <CardHeader title={"Review the data you are copying"} />
            <Divider />
            <CardContent>
                <Grid container justifyContent="center" alignItems="center" spacing={3}>
                    <Grid item xs={4}>
                        <Typography color="textSecondary" variant="body1">
                            From
                        </Typography>
                        {selectedUsers.source && (
                            <Box alignItems="center" display="flex" flexDirection="column">
                                <Typography color="textPrimary" gutterBottom variant="body2">
                                    {selectedUsers.source.users[0]?.id || "None"}
                                </Typography>
                                <Typography color="textPrimary" gutterBottom variant="h3">
                                    {selectedUsers.source.users[0]?.email || "None"}
                                </Typography>
                                <Typography className="data-container" color="textSecondary" variant="body1">
                                    <FaceIcon fontSize="small" />
                                    {selectedUsers.source.users[0]?.name || "None"}
                                </Typography>
                                <Typography className="data-container" color="textSecondary" variant="body1">
                                    <BusinessIcon fontSize="small" />
                                    {selectedUsers.source.users[0]?.company || "None"}
                                </Typography>
                                <Typography className="data-container" color="textSecondary" variant="body1">
                                    <LandscapeIcon fontSize="small" />
                                    {selectedUsers.source.users[0]?.num_fields || "None"}
                                </Typography>
                            </Box>
                        )}
                        {!selectedUsers.source && <div>Select from below</div>}
                    </Grid>
                    <Grid item xs={4}>
                        <Typography color="textSecondary" variant="body1">
                            To
                        </Typography>
                        {selectedUsers.destination && (
                            <Box alignItems="center" display="flex" flexDirection="column">
                                <Typography color="textPrimary" gutterBottom variant="body2">
                                    {selectedUsers.destination.users?.length || "None"} users selected
                                </Typography>
                            </Box>
                        )}
                        {!selectedUsers.destination && <div>Select from below</div>}
                    </Grid>
                    <Grid item xs={4}>
                        <Grid container>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={dataToCopy.permissions !== "false"}
                                            id="permissions-checkbox"
                                            onChange={e => {
                                                handleDataToCopyChange("permissions", e.target.checked)
                                            }}
                                            name="permissions"
                                        />
                                    }
                                    label="Permissions"
                                />
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={dataToCopy.alerts !== "false"}
                                            id="alerts-checkbox"
                                            onChange={e => {
                                                handleDataToCopyChange("alerts", e.target.checked)
                                            }}
                                            name="alerts"
                                        />
                                    }
                                    label="Alerts"
                                />
                                {dataToCopy.alerts !== "false" && (
                                    <RadioGroup
                                        aria-label="alerts"
                                        name="alerts"
                                        value={dataToCopy.alerts}
                                        onChange={e => {
                                            handleModeToCopyChange("alerts", e.target.value)
                                        }}
                                    >
                                        <FormControlLabel
                                            className="data-copymode-label"
                                            value="copy"
                                            id="alerts-copy-radio"
                                            control={<Radio />}
                                            label="Copy"
                                        />
                                        <FormControlLabel
                                            className="data-copymode-label"
                                            value="replace"
                                            id="alerts-replace-radio"
                                            control={<Radio />}
                                            label="Replace"
                                        />
                                    </RadioGroup>
                                )}
                            </Grid>
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={dataToCopy.fields !== "false"}
                                            id="fields-checkbox"
                                            onChange={e => {
                                                handleDataToCopyChange("fields", e.target.checked)
                                            }}
                                            name="fields"
                                        />
                                    }
                                    label="Fields"
                                />
                                {dataToCopy.fields !== "false" && (
                                    <RadioGroup
                                        aria-label="fields"
                                        name="fields"
                                        value={dataToCopy.fields}
                                        onChange={e => {
                                            handleModeToCopyChange("fields", e.target.value)
                                        }}
                                    >
                                        <FormControlLabel
                                            className="data-copymode-label"
                                            id="fields-copy-radio"
                                            value="copy"
                                            control={<Radio />}
                                            label="Copy"
                                        />
                                        <FormControlLabel
                                            className="data-copymode-label"
                                            id="fields-replace-radio"
                                            value="replace"
                                            control={<Radio />}
                                            label="Replace"
                                        />
                                    </RadioGroup>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container spacing={5} justifyContent="center">
                    <Grid item>
                        <Button
                            color="primary"
                            id="copy-btn"
                            variant="contained"
                            onClick={() => {
                                setOpenConfirmModal(true)
                            }}
                            disabled={
                                !selectedUsers.source ||
                                !selectedUsers.destination ||
                                !isDataSelectedToCopy ||
                                isLoading
                            }
                        >
                            Copy!
                        </Button>

                        <ConfirmDialog
                            title={"Do you want to copy all the information from one user to another?"}
                            confirmText="Copy"
                            open={openConfirmModal}
                            setOpen={setOpenConfirmModal}
                            onConfirm={() => {
                                handleUsersCopy(dataToCopy)
                            }}
                        ></ConfirmDialog>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default UserCopySummary
