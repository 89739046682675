import React, { useState } from "react"

// Modules
import clsx from "clsx"
import PropTypes from "prop-types"
import swal from "sweetalert"

// Components
import AddUser from "./AddUser"

// Views

// Context providers / Utils
import networking from "../../../utils/Networking"

// Hooks

// Material-UI *
import { Box, Button } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

// Styles

const useStyles = makeStyles(theme => ({
    root: {},
    importButton: {
        marginRight: theme.spacing(1),
    },
    exportButton: {
        marginRight: theme.spacing(1),
    },
}))

const Toolbar = (props) => {
    const classes = useStyles()
    const { className, onRefresh, sourceAccounts, ...rest } = props

    const [openModal, setOpenModal] = useState(false)
    const [formError, setFormError] = useState("")

    function closeModal() {
        setOpenModal(false)
    }

    function handleAddUser(userData) {
        setFormError("")
        networking
            .post("/users/env/user", userData)
            .then(res => {
                if (res.data.Message && res.data.Message !== "Posted Succesfully") {
                    console.log(res.data.Message)
                    setFormError(res.data.Message)
                } else {
                    swal("Success!", "User added!", "success")
                    setOpenModal(false)
                    onRefresh()
                }
            })
            .catch(err => {
                console.log("Error adding user - ", err)
            })
    }

    return (
        <div className={clsx(classes.root, className)} {...rest}>
            <Box display="flex" justifyContent="flex-end">
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                        setOpenModal(true)
                    }}
                    id="addUserBtn"
                >
                    Add user
                </Button>
            </Box>
            <AddUser isOpen={openModal} closeModal={closeModal} onAddUser={handleAddUser} formError={formError} sourceAccounts={sourceAccounts}/>
            {/*<Box mt={3}>
                <Card>
                    <CardContent>
                        <Box maxWidth={500}>
                            <TextField
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SvgIcon fontSize="small" color="action">
                                                <SearchIcon />
                                            </SvgIcon>
                                        </InputAdornment>
                                    ),
                                }}
                                placeholder="Search user"
                                variant="outlined"
                            />
                        </Box>
                    </CardContent>
                </Card>
            </Box>*/}
        </div>
    )
}

Toolbar.propTypes = {
    className: PropTypes.string,
}

export default Toolbar
