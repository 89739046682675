import axios from "axios"
import config from "./config"
import app from "./Fire"
import { toast } from "react-toastify"
const REST_END_POINT = config.backend_server

let env = "production"

function baseAxios(options) {
    const defaultHeaders = {
        "Content-Type": "application/json",
        "Accept-Language": options.language ? options.language : "en",
        lang: options.lang ? options.lang : "en",
        ...options.extraHeaders,
    }

    const baseUrl = REST_END_POINT

    const instance = axios.create({
        baseURL: baseUrl,
        timeout: options.timeout || 60000,
        headers: defaultHeaders,
    })

    instance.interceptors.response.use(
        function(response) {
            return response
        },
        function(error) {
            console.log(error)
            switch(error.code) {
                case "ECONNABORTED": // intentional fall-through
                case "ETIMEDOUT":
                    toast.warn(`The server is taking a while to process this request. Come back in a couple minutes to check the results.`)
                    break
                default:
                    toast.error(error.message)
                    break
            }
            return Promise.reject(error)
        }
    )

    return instance
}

function executeRequest(method, pathname, data, options = {}) {
    pathname = pathname.replace("env", env)

    const body =
        method === "get" || !data
            ? {}
            : {
                data,
            }
    const reqObj = {
        method,
        url: pathname,
        params: options.query,
        ...body,
    }

    const baseAxiosRequest = baseAxios(options)
    return new Promise((resolve, reject) => {
        return baseAxiosRequest
            .request(reqObj)
            .then(res => {
                resolve(res)
            })
            .catch(error => {
                console.log(error)
                reject(error)
            })
    })
}

export default {
    async get(pathname, options) {
        let reqOptions = await this.addFirebaseAuth(options)
        return executeRequest("get", pathname, null, reqOptions)
    },

    async post(pathname, data, options) {
        let reqOptions = await this.addFirebaseAuth(options)
        return executeRequest("post", pathname, data, reqOptions)
    },

    async put(pathname, data, options) {
        let reqOptions = await this.addFirebaseAuth(options)
        return executeRequest("put", pathname, data, reqOptions)
    },

    async patch(pathname, data, options) {
        let reqOptions = await this.addFirebaseAuth(options)
        return executeRequest("patch", pathname, data, reqOptions)
    },

    async delete(pathname, data, options) {
        let reqOptions = await this.addFirebaseAuth(options)
        return executeRequest("delete", pathname, data, reqOptions)
    },

    async addFirebaseAuth(options = {}) {
        let userToken = await app.auth().currentUser.getIdToken()
        return { ...options, extraHeaders: { "User-Token": userToken } }
    },

    all(promises) {
        return axios.all(promises)
    },

    getEnv() {
        return env
    },

    setEnvTo(environment) {
        env = environment
    },
}
