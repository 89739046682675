import React from "react"
// Modules
import PropTypes from "prop-types"
import clsx from "clsx"

// Components
// Views
// Context providers / Utils
// Hooks
// Material-UI *
import {
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    Divider,
    FormControlLabel,
    Grid,
    TextField,
    MenuItem,
    FormHelperText,
    FormControl,
    Select,
} from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

// Styles

const useStyles = makeStyles({
    root: {},
    item: {
        display: "flex",
        flexDirection: "column",
    },
    padLeft: {
        paddingLeft: "12px",
    },
})

const tabPermissions = ["weather", "climate", "custom"]

const Views = props => {
    const classes = useStyles()
    const { userViews, setUserViews, className, onSaveViews, ...rest } = props

    function handleInputChange(propName, e) {
        setUserViews({ ...userViews, [propName]: e.target.value })
    }

    function handleCheckboxChange(propName, e) {
        setUserViews({ ...userViews, [propName]: e.target.checked })
    }

    return (
        <form className={clsx(classes.root, className)} {...rest}>
            <Card>
                <CardHeader subheader="Permissions" title="Views" />
                <Divider />
                <CardContent>
                    <Grid container spacing={6} wrap="wrap">
                        <Grid className={classes.item} item xs={12}>
                            {userViews &&
                                Object.entries(userViews).map(([key, v]) => {
                                    if (tabPermissions.includes(key)) {
                                        let value = userViews[key]
                                        return (
                                            <FormControlLabel
                                                key={"view-permission-" + key}
                                                control={<Checkbox checked={value || false} />}
                                                label={key}
                                                onChange={e => {
                                                    handleCheckboxChange(key, e)
                                                }}
                                            />
                                        )
                                    }
                                    return null
                                })}
                            {userViews &&
                                Object.entries(userViews).map(([key, v]) => {
                                    if (tabPermissions.includes(key)) return null

                                    let value = userViews[key]
                                    if (key === "default") {
                                        return (
                                            <FormControl key={"view-permission-" + key} className={classes.formControl}>
                                                <Select
                                                    labelId="default-view"
                                                    id="default-view"
                                                    value={value || ""}
                                                    default
                                                    onChange={e => {
                                                        handleInputChange(key, e)
                                                    }}
                                                >
                                                    <MenuItem value={""} disabled></MenuItem>
                                                    {Object.entries(userViews).map(([key, v]) => {
                                                        if (userViews[key] && key !== "default") {
                                                            return (
                                                                <MenuItem value={key} key={"default-view-option" + key}>
                                                                    {key}
                                                                </MenuItem>
                                                            )
                                                        }
                                                        return null
                                                    })}
                                                </Select>
                                                <FormHelperText>default</FormHelperText>
                                                {userViews.default in userViews && !userViews[userViews.default] && (
                                                    <div className="error-message">Please select a default view.</div>
                                                )}
                                            </FormControl>
                                        )
                                    }
                                    if (typeof v === "string") {
                                        return (
                                            <>
                                                <FormControlLabel
                                                    key={"view-permission-" + key}
                                                    control={
                                                        <TextField
                                                            className={classes.padLeft}
                                                            placeholder={key}
                                                            helperText={key}
                                                            value={value || ""}
                                                            onChange={e => {
                                                                handleInputChange(key, e)
                                                            }}
                                                        />
                                                    }
                                                />
                                            </>
                                        )
                                    } else if (typeof v === "boolean") {
                                        return (
                                            <FormControlLabel
                                                key={"view-permission-" + key}
                                                control={<Checkbox checked={value || false} />}
                                                label={key}
                                                onChange={e => {
                                                    handleCheckboxChange(key, e)
                                                }}
                                            />
                                        )
                                    } else if (typeof v === "number") {
                                        console.log("Entering here")
                                        return (
                                            <FormControlLabel
                                                key={"view-permission-" + key}
                                                control={
                                                    <TextField
                                                        className={classes.padLeft}
                                                        placeholder={key}
                                                        helperText={key}
                                                        type="number"
                                                        value={value || ""}
                                                        onChange={e => {
                                                            handleInputChange(key, e)
                                                        }}
                                                    />
                                                }
                                            />
                                        )
                                    } else {
                                        return <></>
                                    }
                                })}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </form>
    )
}

Views.propTypes = {
    className: PropTypes.string,
}

export default Views
