import React, { useState, useEffect, useContext } from "react"
// Modules

// Components
import Page from "src/components/Page"

// Views
import Results from "./Results"
import Toolbar from "./Toolbar"

// Context providers / Utils
import networking from "../../utils/Networking"
import config from "../../utils/config"
import EnvContext from "src/contexts/EnvContext"

// Hooks

// Material-UI *
import { Box, Container } from "@mui/material"

import makeStyles from "@mui/styles/makeStyles"

// Styles

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: "100%",
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
}))

const LocationsView = () => {
    const classes = useStyles()
    const [locations, setLocations] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const envForUrl = config.testing ? "testing" : "env"
    const { env } = useContext(EnvContext)

    async function fetchLocations() {
        setIsLoading(true)
        try {
            let locs = await networking.get("/locations/" + envForUrl + "/locations")
            setLocations(Object.values(locs.data))
            setIsLoading(false)
        } catch (err) {
            console.log("Error getting locations ", err)
            setIsLoading(false)
        }
    }

    // Only one location
    async function handleAddLocation(locationData) {
        setIsLoading(true)
        try {
            let res = await networking.post("/locations/" + envForUrl + "/location", locationData)
            setIsLoading(false)
            return res.data.key
        } catch (err) {
            console.log("Error adding location ", err)
            setIsLoading(false)
            return false
        }
    }

    // More than one location
    async function handleAddLocations(locationData) {
        setIsLoading(true)
        try {
            await networking.post("/locations/" + envForUrl + "/upload_locations", locationData)
            setIsLoading(false)
            return true
        } catch (err) {
            console.log("Error adding location ", err)
            setIsLoading(false)
            return false
        }
    }

    async function handleUpdateLocation(locationData) {
        setIsLoading(true)
        let locId = locationData.id
        try {
            await networking.patch("/locations/" + envForUrl + "/locations/" + locId, locationData)
            setIsLoading(false)
            return true
        } catch (err) {
            console.log("Error adding location ", err)
            setIsLoading(false)
            return false
        }
    }

    async function handleDeleteLocation(locId) {
        setIsLoading(true)
        setIsLoading(false)
        try {
            await networking.delete("/locations/" + envForUrl + "/locations/" + locId)
            setIsLoading(false)
            return true
        } catch (err) {
            console.log("Error adding location ", err)
            setIsLoading(false)
            return false
        }
    }

    function onRefresh() {
        fetchLocations()
    }

    useEffect(() => {
        fetchLocations()
    }, [env])

    return (
        <Page className={classes.root} title="Locations">
            <Container maxWidth={false}>
                <Toolbar onRefresh={onRefresh} onAddLocations={handleAddLocations} />
                <Box mt={3}>
                    <Results
                        locs={locations}
                        onAddLocation={handleAddLocation}
                        onUpdateLocation={handleUpdateLocation}
                        onDeleteLocation={handleDeleteLocation}
                        isLoading={isLoading}
                    />
                </Box>
            </Container>
        </Page>
    )
}

export default LocationsView
