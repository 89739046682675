import React from "react"

import "./LoadingBar.css"

function LoadingBar(props) {
    const { loading = true } = props
    return <>{loading && <div className="table__loader" />}</>
}

export default LoadingBar
