import React, { useState } from "react"
import clsx from "clsx"
import PropTypes from "prop-types"
import swal from "sweetalert"
import AddEditCustomReport from "./AddEditCustomReport"
import networking from "../../../utils/Networking"
import { Box, Button } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    root: {},
    importButton: {
        marginRight: theme.spacing(1),
    },
    exportButton: {
        marginRight: theme.spacing(1),
    },
}))

const CustomReportsToolbar = props => {
    const classes = useStyles()
    const { className, onRefresh, sourceAccounts, ...rest } = props

    const [openModal, setOpenModal] = useState(false)
    const [formError, setFormError] = useState("")

    function closeModal() {
        setOpenModal(false)
    }

    function handleAddReport(reportData) {
        networking
            .post("/custom_analytics/" + networking.getEnv(), {
                name: reportData.reportName,
                file: reportData.reportFile,
                bu_id: reportData.reportSourceAccount,
                type: reportData.reportType,
                tab: reportData.reportTab,
                // "meta": "Report meta",
            })
            .then(res => {
                if (res.status !== 200) {
                    setFormError("There was an error creating the report")
                } else {
                    swal("Success!", "Custom Report added!", "success")
                    setFormError("")
                    setOpenModal(false)
                    onRefresh()
                }
            })
            .catch(err => {
                setFormError("There was an error creating the report")
                console.log("Error adding report - ", err)
            })
    }

    return (
        <div className={clsx(classes.root, className)} {...rest}>
            <Box display="flex" justifyContent="flex-end">
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                        setOpenModal(true)
                    }}
                    id="addCustomReportBtn"
                >
                    Add Report
                </Button>
            </Box>
            <AddEditCustomReport
                isOpen={openModal}
                closeModal={closeModal}
                onAddEditCustomReport={handleAddReport}
                formError={formError}
                sourceAccounts={sourceAccounts}
            />
        </div>
    )
}

CustomReportsToolbar.propTypes = {
    className: PropTypes.string,
}

export default CustomReportsToolbar
