import React, { useState, useEffect, useContext } from "react"
import Page from "src/components/Page"
import CustomReportsTable from "./CustomReportsTable"
import CustomReportsToolbar from "./CustomReportsToolbar"
import { Box, Container } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import networking from "../../../utils/Networking"
import EnvContext from "src/contexts/EnvContext"
import "./customReports.css"

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: "100%",
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
}))

const CustomReportsView = ({}) => {
    const classes = useStyles()
    const [isLoading, setIsLoading] = useState(false)
    const [reports, setReports] = useState([])
    const [sourceAccounts, setSourceAccounts] = useState([])
    const { env } = useContext(EnvContext)

    async function fetchData() {
        let source_accounts = await networking.get("/users/" + networking.getEnv() + "/source_accounts")
        source_accounts.data.this = "Select a source account"
        setSourceAccounts(source_accounts.data)
    }

    async function fetchReports() {
        setIsLoading(true)
        try {
            const reports = (await networking.get("/custom_analytics/" + networking.getEnv())).data.data
            setReports(
                reports.map(report => {
                    return {
                        source_account: report.bu_id,
                        last_updated_by: report.creator,
                        id: report.id,
                        last_updated: new Date(report.last_modified_date).toLocaleString(),
                        name: report.report_name,
                        type: report.report_type === "image" ? "png" : report.report_type,
                        tab: report.report_tab || "custom",
                        file: report.url,
                        // report.meta,
                        // "creation_date",
                    }
                })
            )
            setIsLoading(false)
        } catch (err) {
            console.log("Error getting custom reports ", err)
            setIsLoading(false)
        }
    }

    function fetchAll() {
        fetchReports()
        fetchData()
    }

    useEffect(() => {
        fetchAll()
    }, [env])

    return (
        <Page className={classes.root} title="Custom Reports">
            <Container maxWidth={false}>
                <CustomReportsToolbar onRefresh={fetchAll} sourceAccounts={sourceAccounts} />
                <Box mt={3}>
                    <CustomReportsTable
                        reports={reports}
                        isLoading={isLoading}
                        sourceAccounts={sourceAccounts}
                        fetchAll={fetchAll}
                    />
                </Box>
            </Container>
        </Page>
    )
}

export default CustomReportsView
