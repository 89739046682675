import React, { useEffect, useState, forwardRef } from "react"

// Modules
import PropTypes from "prop-types"
import MaterialTable from "material-table"

// Components
import LoadingBar from "../../components/LoadingBar"

// Views

// Context providers / Utils

// Hooks

// Material-UI
import RoomIcon from "@mui/icons-material/Room"

// Styles
import "./material-table.css"
import TableIcons from "src/components/TableIcons"

const Results = props => {
    const {
        locs = [],
        onAddLocation = () => {},
        onUpdateLocation = () => {},
        onDeleteLocation = () => {},
        isLoading,
    } = props

    const [locations, setLocations] = useState(locs)

    useEffect(() => {
        setLocations(locs)
    }, [locs])
    return (
        <>
            <LoadingBar loading={isLoading} />
            <MaterialTable
                icons={TableIcons}
                columns={[
                    { title: "Area", field: "Area" },
                    { title: "Country", field: "Country" },
                    { title: "ObsStationIndex", field: "ObsStationIndex" },
                    {
                        title: "Lat.",
                        field: "coordinates[lat]",
                        type: "numeric",
                        validate: rowData => {
                            if (!rowData.coordinates?.lat && rowData.coordinates?.lat !== 0) {
                                return { isValid: false, helperText: "Required" }
                            }
                            if (rowData.coordinates?.lat < -90 || rowData.coordinates?.lat > 90) {
                                return { isValid: false, helperText: "Latitude must be between -90 and 90" }
                            } else {
                                return true
                            }
                        },
                    },
                    {
                        title: "Lon.",
                        field: "coordinates[lon]",
                        type: "numeric",
                        validate: rowData => {
                            if (!rowData.coordinates?.lon && rowData.coordinates?.lon !== 0) {
                                return { isValid: false, helperText: "Required" }
                            }
                            if (rowData.coordinates?.lon < -180 || rowData.coordinates?.lon > 180) {
                                return { isValid: false, helperText: "Longitude must be between -180 and 180" }
                            } else {
                                return true
                            }
                        },
                    },
                    { title: "Date Added", field: "Date_Added", defaultSort: "desc" },
                ]}
                data={locations}
                title=""
                options={{
                    grouping: true,
                    pageSize: 20,
                    pageSizeOptions: [5, 10, 20, 40, 60, 80, 100, 200, 300, 400, 500],
                    emptyRowsWhenPaging: false,
                }}
                actions={[
                    {
                        icon: RoomIcon,
                        tooltip: "See location",
                        onClick: (event, rowData) => {
                            window.open(
                                `http://maps.google.com/maps?t=k&q=loc:${rowData.coordinates.lat}+${rowData.coordinates.lon}`,
                                "_blank"
                            )
                        },
                    },
                ]}
                editable={{
                    onRowAddCancelled: rowData => console.log("Row adding cancelled"),
                    onRowUpdateCancelled: rowData => console.log("Row editing cancelled"),
                    onRowAdd: newData =>
                        new Promise((resolve, reject) => {
                            newData.coordinates = [newData.coordinates]
                            onAddLocation(newData).then(locationKey => {
                                if (locationKey) {
                                    let newLocation = { ...newData, id: locationKey }
                                    newLocation.coordinates = newData.coordinates[0]
                                    setLocations([...locations, newLocation])
                                    resolve()
                                    return
                                }
                                console.log("Error adding location")
                                reject()
                                return
                            })
                        }),
                    onRowUpdate: (newData, oldData) =>
                        new Promise((resolve, reject) => {
                            newData.coordinates = [newData.coordinates]
                            onUpdateLocation(newData).then(success => {
                                if (success) {
                                    let updatedId = newData.id
                                    const newLocations = locations.map(loc => {
                                        if (loc.id === updatedId) {
                                            let newLocation = { ...newData }
                                            newLocation.coordinates = newData.coordinates[0]
                                            return newLocation
                                        }
                                        return loc
                                    })

                                    setLocations(newLocations)
                                    resolve()
                                    return
                                }
                                console.log("Error updating location")
                                reject()
                                return
                            })
                        }),
                    onRowDelete: oldData =>
                        onDeleteLocation(oldData.id).then(success => {
                            if (success) {
                                const newLocations = locations.filter(loc => loc.id !== oldData.id)
                                setLocations(newLocations)
                                return
                            }
                            console.log("Error adding location")
                        }),
                }}
            />
        </>
    )
}

Results.propTypes = {
    className: PropTypes.string,
    locs: PropTypes.array.isRequired,
}

export default Results
