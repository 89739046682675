import React from "react"

// Modules
import { useForm } from "react-hook-form"
import TextareaAutosize from "react-textarea-autosize"

// Components

// Views

// Context providers / Utils

// Hooks

// Material-UI *
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Grid, TextField } from "@mui/material"

// Styles
import "./locations.css"

const AddLocations = props => {
    const { isOpen, closeModal, onAddLocations = () => {}, formError } = props

    const { register, handleSubmit, errors } = useForm({
        mode: "onChange",
    })

    const onSubmit = data => {
        onAddLocations(data)
    }

    return (
        <div>
            <Dialog
                open={isOpen}
                onClose={() => {
                    closeModal()
                }}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">Add locations</DialogTitle>
                <DialogContent>
                    <form id="AddLocationsForm" className="add-user-form" onSubmit={handleSubmit(onSubmit)}>
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <TextField type="text" placeholder="Area" name="Area" id="Area" inputRef={register} />
                                {errors.area && <p className="error-message">{errors.area}</p>}
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    type="text"
                                    placeholder="Country"
                                    name="Country"
                                    id="Country"
                                    inputRef={register}
                                />
                                {errors.Country && <p className="error-message">{errors.Country}</p>}
                            </Grid>
                            <Grid item xs={4}>
                                <TextField
                                    type="text"
                                    placeholder="ObsStationIndex"
                                    name="ObsStationIndex"
                                    id="ObsStationIndex"
                                    inputRef={register}
                                />
                                {errors.ObsStationIndex && <p className="error-message">{errors.ObsStationIndex}</p>}
                            </Grid>
                            <Grid item xs={12}>
                                <TextareaAutosize
                                    className="coordinates-container"
                                    type="text"
                                    placeholder="Coordinates."
                                    name="coordinates"
                                    ref={register({
                                        required: "The array of coordinates is required",
                                        validate: value => {
                                            return /^\[((\{"lat": (-?[0-9]+(.[0-9]+)?), "lon": (-?[0-9]+(.[0-9]+)?)\}(, ))+)?(\{"lat": (-?[0-9]+(.[0-9]+)?), "lon": (-?[0-9]+(.[0-9]+)?)\})\]$/gm.test(
                                                value
                                            ) ||
                                            'The array of coordinates should look like [{"lat": float, "lon": float}, ...]';
                                        },
                                    })}
                                    id="coordinates"
                                />
                                {errors.coordinates && <p className="error-message">{errors.coordinates.message}</p>}
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <p className="error-message bigger">{formError}</p>
                <DialogActions>
                    <Button
                        onClick={() => {
                            closeModal()
                        }}
                        color="primary"
                    >
                        Cancel
                    </Button>
                    <Button type="submit" form="AddLocationsForm" color="primary" id="submitBtn">
                        Add locations
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default AddLocations
