import React, { useEffect, useState, useRef } from "react"
import networking from "../../../utils/Networking"
import TextField from "@mui/material/TextField"
import Button from "@mui/material/Button"
import Select from "@mui/material/Select"
import MenuItem from "@mui/material/MenuItem"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import Radio from "@mui/material/Radio"
import RadioGroup from "@mui/material/RadioGroup"
import FormControlLabel from "@mui/material/FormControlLabel"
import FormControl from "@mui/material/FormControl"
import FormLabel from "@mui/material/FormLabel"
import { Typography } from "@mui/material"

export const REPORT_TABS = {
    custom: "Custom",
    regulatory: "Exposure",
}

const AddEditCustomReport = ({
    isOpen,
    closeModal,
    activeReport,
    formError = "",
    onAddEditCustomReport,
    sourceAccounts = [],
}) => {
    const [errors, setErrors] = useState({
        source_account: "",
        report_name: "",
        report_type: "",
        report_file: "",
    })

    const [reportSourceAccount, setReportSourceAccount] = useState("this")
    const [reportName, setReportName] = useState("")
    const [reportFile, setReportFile] = useState("")
    const [reportType, setReportType] = useState("")
    const [reportTab, setReportTab] = useState("custom")

    const [isEditing, setIsEditing] = useState(false)
    const inputFileRef = useRef()

    useEffect(() => {
        if (!isOpen) {
            setErrors({
                source_account: "",
                report_name: "",
                report_type: "",
                report_file: "",
            })
            setReportSourceAccount("this")
            setReportName("")
            setReportType("")
            setReportFile("")
        }
    }, [isOpen])

    useEffect(() => {
        if (activeReport === null || activeReport === undefined) return

        setIsEditing(true)
        setReportSourceAccount(activeReport.source_account)
        setReportName(activeReport.name)
        setReportType(activeReport.type)
        setReportFile(activeReport.file)
        setReportTab(activeReport.tab || "custom")
    }, [activeReport])

    const isFormValid = (update = false) => {
        let isValid = true
        const newErrors = {
            source_account: "",
            report_name: "",
            report_type: "",
            report_file: "",
        }
        if (reportSourceAccount === "" || reportSourceAccount === "this") {
            isValid = false
            newErrors.source_account = "The report source account is required."
        }
        if (reportName === "") {
            isValid = false
            newErrors.report_name = "The report name is required."
        }
        if (reportType === "") {
            isValid = false
            newErrors.report_type = "The report type is required."
        }
        if (reportFile === "") {
            isValid = false
            newErrors.report_file = "The report file is required."
            if (reportType === "looker") {
                newErrors.report_file = "The looker link is required."
            }
        }
        if (reportTab === "") {
            isValid = false
            newErrors.report_tab = "The report tab is required."
        }
        if (update) {
            setErrors({
                ...errors,
                ...newErrors,
            })
        }
        return isValid
    }

    const onSubmit = () => {
        if (!isFormValid(true)) return

        onAddEditCustomReport({
            reportSourceAccount,
            reportName,
            reportType,
            reportFile,
            reportTab,
        })
        setReportSourceAccount("")
        setReportName("")
        setReportType("")
        setReportFile("")
    }

    const getBase64 = (file, cb) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = function() {
            cb(reader.result)
        }
        reader.onerror = function(error) {
            console.log("Error: ", error)
            setErrors({
                ...errors,
                report_file: "Unable to get base64 string from selected file.",
            })
        }
    }

    const handleReportTypeChange = e => {
        setErrors({
            ...errors,
            report_type: "",
            report_file: "",
        })
        setReportFile("")
        setReportType(e.target.value)
    }

    const isFileTypeValid = fileName => {
        setErrors({
            ...errors,
            report_file: "",
        })

        if (fileName.includes("." + reportType)) return true

        setErrors({
            ...errors,
            report_file: "Invalid file type.",
        })
        return false
    }

    const handleFileChange = file => {
        setReportFile("")
        if (!isFileTypeValid(file.name)) return
        getBase64(file, result => {
            setReportFile(result)
        })
    }

    const dragAndDropAttrs = () => {
        if (reportType === "" || reportType === "looker") return {}

        return {
            onDrop: e => {
                e.preventDefault()
                handleFileChange(e.dataTransfer.files[0])
                document.querySelectorAll('[role="dialog"]').forEach(function(el) {
                    el.classList.remove("hovered")
                })
            },
            onDragOver: e => {
                e.preventDefault()
                document.querySelectorAll('[role="dialog"]').forEach(function(el) {
                    el.classList.add("hovered")
                })
            },
            onDragLeave: e => {
                e.preventDefault()
                document.querySelectorAll('[role="dialog"]').forEach(function(el) {
                    el.classList.remove("hovered")
                })
            },
        }
    }

    return (
        <div>
            <Dialog
                id="modal-border"
                open={isOpen}
                onClose={closeModal}
                aria-labelledby="form-dialog-title"
                {...dragAndDropAttrs()}
            >
                <DialogContent>
                    <Typography variant="h5">{isEditing ? "Edit" : "New"} Custom Report</Typography>
                </DialogContent>
                <DialogContent>
                    <DialogContentText className="custom-reports-subtitle">
                        {isEditing ? (
                            <>
                                This custom report is on <b>{networking.getEnv()}</b> environment.
                            </>
                        ) : (
                            <>
                                The new custom report will be added on <b>{networking.getEnv()}</b> environment.
                            </>
                        )}
                    </DialogContentText>

                    <FormControl>
                        <FormLabel className="custom-reports-label">Source Account</FormLabel>
                        <Select
                            disabled={isEditing}
                            fullWidth
                            placeholder="Select a source account"
                            value={reportSourceAccount}
                            onChange={e => {
                                setErrors({
                                    ...errors,
                                    source_account: "",
                                })
                                setReportSourceAccount(e.target.value)
                            }}
                        >
                            {Object.keys(sourceAccounts).map(key => {
                                return (
                                    <MenuItem key={key} value={key}>
                                        {sourceAccounts[key]}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                        {errors.source_account !== "" && <p className="error-message">{errors.source_account}</p>}
                    </FormControl>

                    <FormControl>
                        <FormLabel className="custom-reports-label">Application tab</FormLabel>
                        <Select
                            fullWidth
                            value={reportTab}
                            onChange={e => {
                                setErrors({
                                    ...errors,
                                    report_tab: "",
                                })
                                setReportTab(e.target.value)
                            }}
                        >
                            {Object.keys(REPORT_TABS).map(key => {
                                return (
                                    <MenuItem key={key} value={key}>
                                        {REPORT_TABS[key]}
                                    </MenuItem>
                                )
                            })}
                        </Select>
                        {errors.report_tab !== "" && <p className="error-message">{errors.report_tab}</p>}
                    </FormControl>

                    <FormControl>
                        <FormLabel className="custom-reports-label">Name</FormLabel>
                        <TextField
                            type="text"
                            inputProps={{ maxLength: 50, pattern: "[a-zA-Z0-9-_, ]{1,50}" }}
                            placeholder="Report name"
                            name="report_name"
                            id="report_name"
                            value={reportName}
                            onChange={e => {
                                setErrors({
                                    ...errors,
                                    report_name: e.target.value === "" ? "The report name is required." : "",
                                })
                                setReportName(e.target.value)
                            }}
                        />
                        {errors.report_name !== "" && <p className="error-message">{errors.report_name}</p>}
                    </FormControl>

                    <FormControl>
                        <FormLabel className="custom-reports-label">Type</FormLabel>
                        <RadioGroup
                            row
                            aria-label="report_type"
                            name="report_type"
                            value={reportType}
                            onChange={handleReportTypeChange}
                        >
                            <FormControlLabel
                                value="pdf"
                                control={<Radio color="primary" />}
                                label=".pdf"
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                value="png"
                                control={<Radio color="primary" />}
                                label=".png"
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                value="looker"
                                control={<Radio color="primary" />}
                                label="looker"
                                labelPlacement="start"
                            />
                            <FormControlLabel
                                disabled
                                value="url"
                                control={<Radio color="primary" />}
                                label="3rd party dashboard"
                                labelPlacement="start"
                            />
                        </RadioGroup>
                    </FormControl>
                    {errors.report_type !== "" && <p className="error-message">{errors.report_type}</p>}

                    {reportType !== "" && reportType === "looker" && (
                        <FormControl>
                            <FormLabel className="custom-reports-label">Looker link</FormLabel>
                            <TextField
                                type="text"
                                placeholder="embed/dashboards/3"
                                name="report_file"
                                id="report_file"
                                value={reportFile}
                                onChange={e => {
                                    setErrors({
                                        ...errors,
                                        report_file: e.target.value === "" ? "The report data is required." : "",
                                    })
                                    setReportFile(e.target.value)
                                }}
                            />
                            {errors.report_file !== "" && <p className="error-message">{errors.report_file}</p>}
                        </FormControl>
                    )}

                    {reportType !== "" && reportType !== "looker" && (
                        <>
                            <div
                                className={
                                    "input-file-container" +
                                    (errors.report_file !== "" ? " error" : "") +
                                    (reportFile !== "" ? " selected" : "")
                                }
                                onClick={() => inputFileRef.current.click()}
                            >
                                {reportFile === "" ? (
                                    <div>
                                        {errors.report_file !== ""
                                            ? errors.report_file
                                            : "Drag and drop a file or click to pick one"}
                                    </div>
                                ) : (
                                    <>
                                        <div>
                                            {isEditing
                                                ? activeReport?.file === reportFile
                                                    ? `File ${activeReport?.file} selected`
                                                    : `File ${inputFileRef.current.value} selected`
                                                : `File ${inputFileRef.current.value} selected`}
                                        </div>
                                        <div style={{ paddingTop: "8px" }}>Click to change</div>
                                    </>
                                )}
                            </div>
                            <input
                                ref={inputFileRef}
                                type="file"
                                disabled={reportType === ""}
                                accept={"." + reportType}
                                onClick={e => {
                                    e.target.value = null
                                    setReportFile("")
                                }}
                                onChange={e => {
                                    e.preventDefault()
                                    handleFileChange(e.target.files[0])
                                }}
                                style={{ display: "none" }}
                            ></input>
                        </>
                    )}
                </DialogContent>

                {formError !== "" && <p className="error-message">{formError}</p>}

                <DialogActions>
                    <Button onClick={closeModal} color="primary">
                        Cancel
                    </Button>
                    <Button color="primary" onClick={onSubmit}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default AddEditCustomReport
