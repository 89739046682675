import React from "react"
// Modules
import "src/mixins/chartjs"
import theme from "src/theme"

// Components
import { AuthProvider } from "./contexts/Auth"
import MainComponent from "./MainComponent"

// Views
// Context providers / Utils
// Hooks
// Material-UI *
import { ThemeProvider, StyledEngineProvider } from "@mui/material";

// Styles
import "react-toastify/dist/ReactToastify.min.css"
import "react-perfect-scrollbar/dist/css/styles.css"

const App = () => {
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <AuthProvider>
                    <MainComponent />
                </AuthProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default App
