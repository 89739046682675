import React, { useState } from 'react'

import CopyUserView from "./CopyUserView";
import ClimateMirrorView from './ClimateMirrorView';

import { Button } from "@mui/material";

function CopyToolView() {
    const [view, setView] = useState("seasonal")

    return (
        <div>
            <div style={{ display: "flex", marginLeft: "30px", marginTop: "15px", gap: "30px" }}>
                <Button
                    variant="contained"
                    color={view === "seasonal" ? "primary": "default"}
                    onClick={() => setView("seasonal")}
                >
                    Seasonal
                </Button>
                <Button
                    variant="contained"
                    color={view === "climate" ? "primary": "default"}
                    onClick={() => setView("climate")}
                >
                    Climate
                </Button>
            </div>
            {view === "seasonal" && <CopyUserView />}
            {view === "climate" && <ClimateMirrorView />}
        </div>
    )
}

export default CopyToolView
