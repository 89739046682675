import React, { useState } from "react"
// Modules
import swal from "sweetalert"

// Components
import Page from "src/components/Page"
import UserSelection from "../components/UserSelection"
import UsersCopySummary from "../components/UsersCopySummary"

// Views

// Context providers / Utils
import networking from "../../../utils/Networking"
import { sortObjectAlphabetically } from "../../../utils/sorting"

// Hooks

// Material-UI *
import { Grid, Container } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

// Styles

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: "100%",
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
}))

const CopyUserView = () => {
    const classes = useStyles()

    const [selectedUsers, setSelectedUsers] = useState({
        /*source: {
            id: "idSource",
            data: {
                email: "source@email.com",
                name: "source",
                company: "Source Company and Co.",
                num_fields: 10,
            },
        },
        destination: {
            id: "idDestination",
            data: {
                email: "destination@email.com",
                name: "destination",
                company: "Destination Company and Co.",
                num_fields: 10,
            },
        },*/
    })

    const [allUsers, setAllUsers] = useState({
        dev: [],
        staging: [],
        production: [],
    })

    const [isLoading, setIsLoading] = useState(false)
    const [isRequestPending, setPendingRequest] = useState(false)

    function onUserSelected(propName) {
        return selUsers => {
            setSelectedUsers({
                ...selectedUsers,
                [propName]: {
                    users: selUsers.users,
                    env: selUsers.env,
                },
            })
        }
    }

    function fetchUsers() {
        return async env => {
            try {
                setPendingRequest(true)
                let mUsers = await networking.get("/users/" + env + "/users_data")
                let sortedUsers = sortObjectAlphabetically(mUsers.data, "email")
                setAllUsers({ ...allUsers, [env]: sortedUsers })
                setPendingRequest(false)
                return sortedUsers
            } catch (err) {
                setPendingRequest(false)
                console.log("Error getting users ", err)
            }
        }
    }

    function onCopyUsers(payload) {
        setIsLoading(true)
        networking
            .post(
                `/tools/copy_data`,
                {
                    payload,
                    selectedUsers,
                },
                {
                    timeout: 40000,
                }
            )
            .then(res => {
                console.log("RES = ", res)
                if (res.status === 200) {
                    swal("Success!", JSON.stringify(res.data), "success")
                } else {
                    console.log("Error copying user - ", res.data)
                }
                setIsLoading(false)
            })
            .catch(err => {
                console.log("Error copying user - ", err)
                setIsLoading(false)
            })
    }

    return (
        <Page className={classes.root} title="Copy Users">
            <Container maxWidth="lg">
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <UsersCopySummary
                            selectedUsers={selectedUsers}
                            handleUsersCopy={onCopyUsers}
                            isLoading={isLoading}
                        />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                        <UserSelection
                            title="source"
                            getUsers={fetchUsers}
                            users={allUsers}
                            handleUserSelected={onUserSelected("source")}
                            isRequestPending={isRequestPending}
                        />
                    </Grid>
                    <Grid item lg={6} md={6} xs={12}>
                        <UserSelection
                            title="destination"
                            getUsers={fetchUsers}
                            users={allUsers}
                            handleUserSelected={onUserSelected("destination")}
                            isRequestPending={isRequestPending}
                        />
                    </Grid>
                </Grid>
            </Container>
        </Page>
    )
}

export default CopyUserView
