import React, { useState, useEffect } from "react"
// Modules
import { useParams } from "react-router-dom"
import swal from "sweetalert"
import { useNavigate } from "react-router-dom"

// Components
import LoadingBar from "../../../components/LoadingBar"
import Page from "src/components/Page"

// Views
import UserData from "./UserData"
import UserPermissions from "./UserPermissions"
import FieldCreation from "./UserPermissions/FieldsFunctionality/FieldCreation"
import FieldEdition from "./UserPermissions/FieldsFunctionality/FieldEdition"
import CropCreation from "./UserPermissions/FieldsFunctionality/CropCreation"
// Context providers / Utils
import networking from "../../../utils/Networking"

// Hooks
// Material-UI *
import { Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

import * as EmailValidator from "email-validator"
// Styles
import "./UserProfile.css"
import { usePrompt } from "src/utils/router-block-workaround"

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: "100%",
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
}))

const UserProfile = () => {
    const { uid } = useParams()
    const [user, setUser] = useState({
        email: "",
        name: "",
        company: "",
    })
    const [userEmails, setUserEmails] = useState([])
    const [permissions, setPermissions] = useState({
        views: {},
        features: {
            alerts: {},
            field_tools: {},
            report_generation: {},
            satellite_tools: {},
        },
    })
    const [timezones, setTimezones] = useState([])
    const [units, setUnits] = useState([])
    const [sourceAccounts, setSourceAccounts] = useState([])
    const [oldUser, setOldUser] = useState({ ...user })
    const [oldUserEmails, setOldUserEmails] = useState({ ...userEmails })
    const [oldPermissions, setOldPermissions] = useState({ ...permissions })

    const [isLoading, setIsLoading] = useState(true)
    const [openUserEmailDialog, setOpenUserEmailDialog] = useState(false)
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [emailError, setEmailError] = useState(false)
    const [addButtonDisabled, setAddButtonDisabled] = useState(true)

    const navigate = useNavigate()

    const setUserData = (value, prop) => {
        setUser({ ...user, [prop]: value })
    }

    const handleOpenUserEmailDialog = () => {
        setOpenUserEmailDialog(true)
    }

    const handleCloseUserEmailDialog = () => {
        setOpenUserEmailDialog(false)
    }

    const addUserEmail = () => {
        userEmails.push({
            name: name,
            email: email,
        })
        setUserEmails(userEmails)
        setName("")
        setEmail("")
        handleCloseUserEmailDialog()
    }

    const deleteUserEmail = idx => {
        setUserEmails(userEmails.filter((_, i) => i !== idx))
    }

    const classes = useStyles()

    function fetchUserData() {
        setIsLoading(true)
        networking
            .get("/users/env/user/" + uid + "/all-data")
            .then(res => {
                let userData = res.data.data
                setUserEmails([...userData.user_emails])
                setOldUserEmails([...userData.user_emails])
                delete userData.user_emails
                setPermissions(userData.permissions)
                setOldPermissions(userData.permissions)
                delete userData.permissions
                setTimezones(userData.timezones)
                delete userData.timezones
                setUnits(userData.units)
                delete userData.units
                setSourceAccounts(userData.source_accounts)
                delete userData.source_accounts
                const newUser = { ...user, ...userData }
                setUser(newUser)
                setOldUser(newUser)
                setIsLoading(false)
            })
            .catch(err => {
                swal("Error!", "We couldn't retrieve all the user data", "error")
                console.log(err)
                setIsLoading(false)
            })
    }

    function saveUser() {
        networking
            .patch("/users/env/user/" + uid, { ...user })
            .then(res => {
                if (res.data.Message && res.data.Message !== "Updated Succesfully") {
                    swal("Error!", "We couldn't update the user data", "error")
                    console.log(res.data.Message)
                } else {
                    swal("Success!", "User updated!", "success")
                    setOldUser({ ...user })
                }
            })
            .catch(err => {
                swal("Error!", "We couldn't update the user data", "error")
                console.log(err)
            })
    }

    function handleDeleteUser() {
        networking
            .delete("/users/env/user/" + uid)
            .then(res => {
                if (res.data.Message && res.data.Message !== "Deleted Succesfully") {
                    swal("Error!", "We couldn't delete the user", "error")
                    console.log(res.data.Message)
                } else {
                    swal("Success!", "User deleted!", "success")
                    navigate("/app/users")
                }
            })
            .catch(err => {
                swal("Error!", "We couldn't delete the user", "error")
                console.log(err)
            })
    }

    async function saveUserPermissions() {
        return networking
            .post("/permissions/env/permissions/" + uid, { ...permissions })
            .then(res => {
                if (res.data.Message && res.data.Message !== "Posted Succesfully") {
                    console.log(res.data.Message)
                    return swal("Error!", "We couldn't update the user permissions", "error").then(() => {
                        return
                    })
                } else {
                    setOldPermissions({ ...permissions })
                    return swal("Success!", "Permissions updated!", "success").then(() => {
                        return
                    })
                }
            })
            .catch(err => {
                console.log(err)
                return swal("Error!", err, "error").then(() => {
                    return
                })
            })
    }

    const saveUserEmails = async () => {
        return networking
            .put(`/users/env/user/${uid}/user_email`, {
                user_emails: userEmails,
            })
            .then(res => {
                if (res.data.Message && res.data.Message !== "Updated Succesfully") {
                    console.log(res.data.Message)
                    return swal("Error!", "We couldn't save the extra user notification emails.", "error").then(() => {
                        return
                    })
                } else {
                    setOldUserEmails([...userEmails])
                    return swal("Success!", "User Configurations updated!", "success").then(() => {
                        return
                    })
                }
            })
            .catch(err => {
                console.log(err)
                return swal("Error!", "We couldn't save the user emails.", "error").then(() => {
                    return
                })
            })
    }

    const validateEmail = () => {
        let emailValid = EmailValidator.validate(email)
        if (emailValid) {
            setAddButtonDisabled(false)
            setEmailError(false)
        } else {
            setEmailError(true)
        }
    }

    const setUserPermissions = (newData, type) => {
        if (type === "features") setPermissions({ ...permissions, features: { ...permissions.features, ...newData } })
        else if (type === "views") setPermissions({ ...permissions, views: { ...permissions.views, ...newData } })
    }

    useEffect(() => {
        fetchUserData()
    }, [])

    const hasChanges =
        JSON.stringify(permissions) !== JSON.stringify(oldPermissions) ||
        JSON.stringify(userEmails) !== JSON.stringify(oldUserEmails) ||
        JSON.stringify(user) !== JSON.stringify(oldUser)

    const save = async () => {
        if (JSON.stringify(permissions) !== JSON.stringify(oldPermissions)) {
            await saveUserPermissions()
        }
        if (JSON.stringify(userEmails) !== JSON.stringify(oldUserEmails)) {
            await saveUserEmails()
        }

        if (user.timezone === "None" || user.timezone === null || user.timezone === undefined || user.timezone === "") {
            swal("Error!", "Please fill in the timezone", "error")
        } else if (JSON.stringify(user) !== JSON.stringify(oldUser)) {
            document.getElementById("editableData-submitBtn").click()
            setTimeout(() => {
                if (document.getElementsByClassName("error-message").length > 0) {
                    swal("Error!", "There is an error in the user form.", "error")
                } else {
                    saveUser()
                }
            }, 400)
        }
    }

    const [fields, setFields] = useState([])
    const [crops, setCrops] = useState([])
    const [rows, setRows] = useState(5)
    const [currentPage, setCurrentPage] = useState(1)
    const [searchedFields, setSearchedFields] = useState([])

    function fetchFieldsData() {
        networking
            .get("/fields/env/" + uid)
            .then(res => {
                let data = res.data.data
                setFields(data.reverse())
                setSearchedFields(data)
            })
            .catch(err => {
                swal("Error!", "We couldn't get the fields data", "error")
                console.log(err)
            })
    }

    function fetchCropsData() {
        const defaultCropsFetch = networking
            .get("/crops/env/default")
            .then(res => {
                let defaultCropData = res.data.data
                return defaultCropData
            })
            .catch(err => {
                swal("Error!", "We couldn't get the crops data", "error")
                console.log(err)
            })
        const userCropsFetch = networking
            .get(`/crops/env/${uid}`)
            .then(res => {
                let userCropData = res.data.data
                return userCropData
            })
            .catch(err => {
                swal("Error!", "We couldn't get the user's crops data", "error")
                console.log(err)
            })
        Promise.allSettled([defaultCropsFetch, userCropsFetch]).then(results => {
            let allCropsFetch = {}
            results.forEach(result => (allCropsFetch = { ...allCropsFetch, ...result.value }))
            const cropsArray = Object.keys(allCropsFetch).map(key => allCropsFetch[key])
            setCrops(cropsArray)
        })
    }

    function handleCropCreation(e) {
        let cropId
        let varietyId
        let cropCreationPromise = networking.post(`/crops/env/${uid}`, { name: e.crop }).then(res => {
            if (res.data.data.message && res.data.data.message !== "Crop created") {
                console.log(res.data.data.message)
                return swal("Error!", "We couldn't create the crop.", "error")
            } else {
                cropId = res.data.data.crop_id
            }
        })
        Promise.all([cropCreationPromise]).then(() => {
            networking
                .post(`/crops/env/${cropId}/varieties`, {
                    crop_id: cropId,
                    name: e.variety,
                    type: "days",
                    gdds_base_temp: 0,
                })
                .then(res => {
                    if (res.data.data.messagee && res.data.data.message !== "Variety created") {
                        console.log(res.data.data.message)
                        return swal("Error!", "We couldn't create the variety.", "error")
                    } else {
                        varietyId = res.data.data.variety_id
                        return swal("Success", "Crop and Variety succesfully created", "success").then(fetchCropsData())
                    }
                })
        })
    }

    function handleFieldCreation(e) {
        let isCropNew = true
        let isVarietyNew = true
        crops.forEach(item => {
            if (item.name === e.crop) {
                isCropNew = false
                if (item?.varieties[Object.keys(item.varieties)]?.name === e.variety) isVarietyNew = false
            } else return
        })
        // Add empty labels object to field before hitting POST
        // This prevents the request from crashing at AdminDashboardAPI
        e["labels"] = {}
        networking
            .post("/fields/env/" + uid, e)
            .then(res => {
                if (res.data.Message && res.data.Message !== "Field added succesfully") {
                    console.log(res.data.Message)
                    return swal("Error!", "We couldn't create the field.", "error")
                } else {
                    return swal("Success!", "Field successfully created", "success").then(
                        setSearchedFields([e, ...fields])
                    )
                }
            })
            .catch(err => {
                swal("Error!", "We couldn't create the field.", "error")
                console.log(err)
            })
    }

    function handleFieldEdit(e) {
        networking
            .put(`fields/env/${uid}/${e.uuid}`, e)
            .then(res => {
                if (res.data.Message && res.data.Message !== "Field updated succesfully") {
                    console.log(res.data.Message)
                    return swal("Error!", "We couldn't update the field.", "error")
                } else {
                    return swal("Success!", "Field updated!", "success")
                }
            })
            .catch(err => {
                swal("Error!", "We couldn't update the field.", "error")
                console.log(err)
            })
    }

    function handleFieldDelete(e) {
        swal({
            title: `Are you sure you want to delete "${e.name}" field?`,
            text: "Once deleted this field cannot be recovered",
            icon: "warning",
            buttons: ["Cancel", "Delete"],
            dangerMode: true,
        }).then(value => {
            if (value) {
                networking
                    .delete(`fields/env/${uid}/${e.uuid}`, e)
                    .then(res => {
                        if (res.data.Message && res.data.Message !== "Field deleted successfully") {
                            console.log(res.data.Message)
                            return swal("Error!", "We couldn't delete the field.", "error")
                        } else {
                            setFields(fields.filter(field => field.uuid !== e.uuid))
                            return swal("Success!", "Field deleted!", "success")
                        }
                    })
                    .catch(err => {
                        swal("Error!", "We couldn't delete the field.", "error")
                        console.log(err)
                    })
            }
        })
    }

    function handlePageChange(value) {
        setCurrentPage(value)
    }

    function handleRowChange(value) {
        setRows(value)
    }

    function handleFieldSearch(e) {
        const searchValue = e.toLowerCase()
        if (e === "") {
            setSearchedFields(fields)
        } else {
            const searchResult = fields.filter(field => {
                return (
                    field.name.toLowerCase().includes(searchValue) ||
                    field.crop.toLowerCase().includes(searchValue) ||
                    field.variety.toLowerCase().includes(searchValue) ||
                    field.region.toLowerCase().includes(searchValue)
                )
            })
            setSearchedFields(searchResult)
        }
    }

    function handleFieldSearchClear() {
        setSearchedFields(fields)
    }

    useEffect(() => {
        fetchFieldsData()
        fetchCropsData()
    }, [])

    usePrompt("Are you sure you want to leave?", hasChanges)

    return (
        <div style={{ position: "relative" }}>
            <LoadingBar loading={isLoading} />
            <Dialog open={openUserEmailDialog} onClose={handleCloseUserEmailDialog} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Add User Email</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Name"
                        fullWidth
                        onChange={e => setName(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        id="email"
                        label="Email Address"
                        type="email"
                        fullWidth
                        onChange={e => setEmail(e.target.value)}
                        onBlur={validateEmail}
                        error={emailError}
                        helperText={emailError ? "The email is wrongly formatted" : ""}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseUserEmailDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={addUserEmail} color="primary" disabled={addButtonDisabled}>
                        Add User
                    </Button>
                </DialogActions>
            </Dialog>
            <Page className={classes.root} title="User Profile">
                <UserData
                    user={user}
                    oldUser={oldUser}
                    timezones={timezones}
                    units={units}
                    sourceAccounts={sourceAccounts}
                    setUserData={setUserData}
                    onDeleteUser={handleDeleteUser}
                />
                <UserPermissions
                    setUserPermissions={setUserPermissions}
                    userPermissions={permissions}
                    userEmails={userEmails}
                    handleOpenUserEmailDialog={handleOpenUserEmailDialog}
                    deleteUserEmail={deleteUserEmail}
                />
                <CropCreation setCreatedCrop={handleCropCreation} />
                <FieldCreation crops={crops} setCreatedField={handleFieldCreation} />
                <FieldEdition
                    fields={searchedFields}
                    crops={crops}
                    rows={rows}
                    rowsList={[`all (${searchedFields.length})`, 5, 10, 15, 20]}
                    currentPage={currentPage}
                    handlePageChange={handlePageChange}
                    handleRowChange={handleRowChange}
                    dataLength={searchedFields.length}
                    handleFieldDelete={handleFieldDelete}
                    handleFieldEdit={handleFieldEdit}
                    handleFieldSearch={handleFieldSearch}
                    handleFieldSearchClear={handleFieldSearchClear}
                />
            </Page>
            <Button
                className="floating-button"
                onClick={save}
                disabled={!hasChanges}
                color="primary"
                variant="contained"
                size="large"
            >
                {hasChanges ? "Save your current changes!" : "No changes"}
            </Button>
        </div>
    )
}

export default UserProfile
