import React, { useState } from "react"
// Modules

// Components
import LoadingBar from "../../../components/LoadingBar"

// Views

// Context providers / Utils

// Hooks

// Material-UI *
import {
    Button,
    Grid,
    CardHeader,
    Divider,
    Card,
    CardContent,
    Select,
    MenuItem,
    FormHelperText,
} from "@mui/material"

// Styles
import "./UserSelection.css"
import MaterialTable from "material-table"
import TableIcons from "src/components/TableIcons"

const UserSelection = props => {
    const { title, users, getUsers = () => {}, handleUserSelected = () => {}, isRequestPending } = props

    const [env, setEnv] = useState("production")
    const [usersArrList, setUsersArrList] = useState([])
    // This property is just for the source
    const [selectedUserId, setSelectedUserId] = useState('')
    const [isLoading, setIsLoading] = useState(false)

    const handleChange = event => {
        setEnv(event.target.value)
    }

    async function onGetUsers() {
        setIsLoading(true)
        if (users[env].length === 0) {
            let getUsersFunction = getUsers()
            setUsersArrList(await getUsersFunction(env))
        } else {
            setUsersArrList(JSON.parse(JSON.stringify(users[env])))
        }
        setIsLoading(false)
    }

    function selectUsers(users) {
        if (title !== 'destination') {
            setSelectedUserId(users[0]?.user_id)
        }
        handleUserSelected({ users, env })
    }

    return (
        <Card>
            <CardHeader subheader={"Choose the " + title + " user"} title={title.toUpperCase()} />
            <Divider />
            <CardContent>
                <Grid container justifyContent="center" alignItems="center" spacing={3}>
                    <Grid item xs={8}>
                        <Select id={"env-" + title} value={env} onChange={handleChange}>
                            <MenuItem value={"dev"}>Development</MenuItem>
                            <MenuItem value={"staging"}>Staging</MenuItem>
                            <MenuItem value={"production"}>Production</MenuItem>
                        </Select>
                        <FormHelperText>Select {title} environment</FormHelperText>
                    </Grid>
                    <Grid item xs={4}>
                        <Button
                            id={title + "-get-users-btn"}
                            color="primary"
                            variant="contained"
                            onClick={onGetUsers}
                            disabled={isRequestPending}
                        >
                            Get Users
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <LoadingBar loading={isLoading} />
                        <MaterialTable
                            columns={[
                                { title: 'Email', field: 'email'},
                                { title: 'Name', field: 'name'}
                            ]}
                            data={usersArrList.map((userArr) => {
                                let user = userArr[1]
                                user['user_id'] = userArr[0]
                                return user
                            })}
                            icons={TableIcons}
                            options={{
                                pageSize: 20,
                                showTitle: false,
                                searchFieldAlignment: 'left',
                                selection: title === 'destination',
                                rowStyle: rowData => {
                                    if(rowData.user_id === selectedUserId) {
                                        return { backgroundColor: '#219a921c'};
                                    }
                                    
                                    return {};
                                }
                            }}
                            onRowClick={(event, user) => selectUsers([user])}
                            onSelectionChange={(users) => selectUsers(users)}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

export default UserSelection
