import React from "react"
// Modules
// Components
import Data from "./Data"
import EditableData from "./EditableData"

// Views
// Context providers / Utils
// Hooks
// Material-UI *
import { Container, Grid } from "@mui/material"

// Styles

const UserData = props => {
    const { user, oldUser, timezones, units, sourceAccounts, setUserData, onDeleteUser = () => {} } = props

    return (
        <Container maxWidth="lg">
            <Grid container spacing={3}>
                <Grid item lg={4} md={6} xs={12}>
                    <Data userData={oldUser} onDeleteUser={onDeleteUser} />
                </Grid>
                <Grid item lg={8} md={6} xs={12}>
                    <EditableData
                        userData={user}
                        timezones={timezones}
                        units={units}
                        sourceAccounts={sourceAccounts}
                        setUserData={setUserData}
                    />
                </Grid>
            </Grid>
        </Container>
    )
}

export default UserData
