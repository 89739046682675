import React, { useState } from "react"
import LoadingBar from "../../../components/LoadingBar"
import MaterialTable from "material-table"
import EditIcon from "@mui/icons-material/Edit"
import DeleteIcon from "@mui/icons-material/Delete"
import { Card } from "@mui/material";
import { adaptV4Theme } from "@mui/material/styles";
import { createTheme, ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import TableIcons from "src/components/TableIcons"
import AddEditCustomReport, { REPORT_TABS } from "./AddEditCustomReport"
import swal from "sweetalert"
import networking from "../../../utils/Networking"

const CustomReportsTable = ({ reports = [], isLoading, sourceAccounts = [], fetchAll }) => {
    const theme = createTheme({})

    const [openModal, setOpenModal] = useState(false)
    const [formError, setFormError] = useState("")
    const [activeReport, setActiveReport] = useState(null)

    const openEditModal = report => {
        setActiveReport(report)
        setOpenModal(true)
    }

    const handleEditReport = report => {
        const editedReport = {
            ...activeReport,
            ...report,
        }
        networking
            .put("/custom_analytics/" + networking.getEnv() + "/" + editedReport.id, {
                name: editedReport.reportName,
                file: editedReport.reportFile,
                url: editedReport.file, // To avoid report file from updating when no need to
                type: editedReport.reportType === "png" ? "image" : editedReport.reportType,
                tab: editedReport.reportTab,
                bu_id: editedReport.reportSourceAccount,
                // "meta": "Report meta",
            })
            .then(res => {
                if (res.status !== 200) {
                    setFormError("There was an error updating the report")
                } else {
                    setOpenModal(false)
                    swal("Success!", "Custom Report Updated!", "success")
                    setFormError("")
                    setActiveReport(null)
                    fetchAll()
                }
            })
            .catch(err => {
                setFormError("There was an error updating the report")
                console.log("Error adding report - ", err)
            })
    }

    const handleDeleteReport = report => {
        networking
            .delete("/custom_analytics/" + networking.getEnv() + "/" + report.id, { bu_id: report.source_account })
            .then(res => {
                if (res.status !== 200) {
                    setFormError("There was an error deleting the report")
                } else {
                    setOpenModal(false)
                    swal("Success!", "Custom Report Deleted!", "success")
                    setFormError("")
                    setActiveReport(null)
                    fetchAll()
                }
            })
            .catch(err => {
                setFormError("There was an error deleting the report")
                console.log("Error adding report - ", err)
            })
    }

    return <>
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <Card>
                    <LoadingBar loading={isLoading} />
                    <MaterialTable
                        columns={[
                            { title: "Source Account", field: "source_account_name" },
                            { title: "Report Name", field: "name" },
                            { title: "Report Tab", field: "tab_name" },
                            { title: "Last Updated", field: "last_updated" },
                            { title: "Last Updated By", field: "last_updated_by" },
                        ]}
                        data={reports.map(report => {
                            return {
                                ...report,
                                source_account_name: sourceAccounts[report.source_account],
                                tab_name: REPORT_TABS[report.tab],
                            }
                        })}
                        icons={TableIcons}
                        title="Custom Reports"
                        options={{
                            pageSize: 20,
                            pageSizeOptions: [5, 10, 20, 40, 60, 80, 100],
                            emptyRowsWhenPaging: false,
                        }}
                        actions={[
                            {
                                tooltip: "Edit",
                                icon: () => <EditIcon />,
                                onClick: (_, report) => openEditModal(report),
                            },
                            {
                                tooltip: "Delete",
                                icon: () => <DeleteIcon />,
                                onClick: (_, report) => {
                                    swal({
                                        title: "Wait!",
                                        text: "Are you sure you want to delete this report?",
                                        icon: "warning",
                                        dangerMode: true,
                                        buttons: true,
                                    }).then(result => {
                                        if (result) {
                                            handleDeleteReport(report)
                                        }
                                    })
                                },
                            },
                        ]}
                    />
                </Card>
            </ThemeProvider>
        </StyledEngineProvider>
        <AddEditCustomReport
            isOpen={openModal}
            closeModal={() => {
                setActiveReport(null)
                setOpenModal(false)
            }}
            activeReport={activeReport}
            onAddEditCustomReport={handleEditReport}
            formError={formError}
            sourceAccounts={sourceAccounts}
        />
    </>;
}

export default CustomReportsTable
