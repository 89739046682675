import React, { useState, useEffect } from 'react'
// Modules
import clsx from "clsx"
import swal from "sweetalert"

// Material UI
import { Card, CardContent, CardHeader, Divider, TextField, Button, Menu, MenuItem } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

// Styles
const useStyles = makeStyles({
    root: {
        padding: "0 18px",
        width: "100%",
    },
    button: {
        margin: "15px"
    },
    cardContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 20,
    },
})

const initialFieldState = {
    name: "",
    crop: "",
    variety: "",
    region: "",
    plantingDateP: "",
    notes: "",
    crop_id: 0,
    variety_id: 0,
    weatherfile: "",
    Shapefile: "",
}

const SHAPEFILE_EXTENSIONS = [
    ".geojson",
    ".kml",
    ".zip"
]

const ALLOWED_SHAPEFILE_GEOMETRY_TYPES = [
    "Point",
    "Polygon",
    "MultiPolygon"
]

const FieldCreation = ({ crops, setCreatedField} ) => {
    const [field, setField] = useState(initialFieldState)
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const [anchorElCrop, setAnchorElCrop] = useState(null)
    const [anchorElVariety, setAnchorElVariety] = useState(null)

    const classes = useStyles()

    let _varieties = crops.filter(crop => crop.id === field.crop_id)[0]?.varieties
    let varietiesArray = _varieties ? Object.keys(_varieties).map(key => _varieties[key]) : []

    const openCropMenu = Boolean(anchorElCrop)
    const openVarietyMenu = Boolean(anchorElVariety)

    function verifyShapefile(shapefile, successAction){
        // Checks that an uploaded shapefile has
        // 1. Correct file extension
        // 2. Correct Feature geometry types
        // If it passes both tasks, executes successAction

        let fileExtension = shapefile.name.match(/\.([^\.]+)$/)[1];
        
        if (SHAPEFILE_EXTENSIONS.includes('.' + fileExtension)){
            if (fileExtension === 'geojson') {
                // Since its a geojson, check for correct geometry types
                let reader = new FileReader()
                reader.readAsText(shapefile)

                reader.onload = () => {
                    let iterator = 0
                    let fileContents = JSON.parse(reader.result)
                    for (const feature of fileContents["features"]){
                        let geoType = feature["geometry"]["type"]
                        if (!ALLOWED_SHAPEFILE_GEOMETRY_TYPES.includes(geoType)){
                            swal("Error!", `Unsuported Shapefile Geometry format (${geoType}) in Feature ${iterator}`, "error")
                            return
                        }
                        iterator++
                    }
                    successAction()
                }
            } else {
                // Not a geojson, geometry types will get checked at the backend
                successAction()
            }
        } else {
            swal("Error!", `Unsuported Shapefile format (.${fileExtension})`, "error")
        }
    }

    function handleInputChange (propName, e) {
        let targetText = e.target.value
        setField({...field, [propName] : targetText})
    }

    function handleMenuItemSelection (propName, e) {
        let value = e.target.attributes.value.value
        let id = parseInt(e.target.attributes.id.value)
        setField({ ...field, [propName]: value, [`${propName}_id`]: id })
    }

    function handleFileChange(e) {
        let uploadedFile = e.target.files[0]
        verifyShapefile(uploadedFile, () => {
            // Used to convert file to Base64
            let reader = new FileReader()
            reader.readAsDataURL(uploadedFile)
            reader.onload = () => {
                // Removes the starting metadata
                let encoded = reader.result.toString().replace(/^data:(.*,)?/, '')
                if ((encoded.length % 4) > 0) {
                    encoded += '='.repeat(4 - (encoded.length % 4))
                }
                setField({ ...field, Shapefile: encoded })
            }
            reader.onerror = () => {
                swal("Error!", "Couldn't load file", "error")
            }
        })
    }

    function checkFields() {
        if ("name" in field  && "crop" in field && "variety" in field && "region" in field && field["name"] !== "" 
        && field["crop"] !== "" && field["variety"] !== "" && field["region"] !== "" && "Shapefile" in field 
        && field["Shapefile"] !== "") {
            setButtonDisabled(false)
        } else {
            setButtonDisabled(true)
        }
    }

    function handleSaveButton() {
        setCreatedField(field)
        setField(initialFieldState)
    }

    useEffect(() => {
        checkFields()
    }, [field])

    useEffect(() => {
        setField({ ...field, "variety": "", "variety_id": 0 })
    }, [field.crop])

    return (
        <form className={clsx(classes.root)}>
            <Card className={classes.card}>
                <Divider />
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <CardHeader title="Field Creation" subheader="Create fields for the user" />
                    <Button 
                        className={classes.button} 
                        color="primary" 
                        variant="contained"
                        onClick={() => handleSaveButton()}
                        disabled={buttonDisabled}
                    >
                        Save
                    </Button>
                </div>
                <Divider />
                <CardContent className={classes.cardContent}>
                    <div style={{ display: "flex", justifyContent: "space-between", gap: 15, width: "100%" }}>
                        <TextField 
                            type="text" 
                            label='Field' 
                            variant="outlined" 
                            InputLabelProps={{shrink: true}}
                            value={field.name}
                            onChange={e => handleInputChange("name", e)}
                        />
                        <TextField 
                            type="select" 
                            label='Crop' 
                            variant="outlined" 
                            InputLabelProps={{shrink: true}}
                            value={field.crop}
                            onClick={e => setAnchorElCrop(e.currentTarget)}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                        <Menu
                            anchorEl={anchorElCrop}
                            open={openCropMenu}
                            onClose={() => setAnchorElCrop(null)}
                            style={{  marginTop: 50, maxHeight: 300 }}
                        >
                            {crops.map(crop => (
                                <MenuItem
                                    key={`field-creation-crop-${crop.id}`}
                                    id={crop.id}
                                    value={crop.name}
                                    style={{ width: 250 }}
                                    onClick={(e) => handleMenuItemSelection("crop", e) }
                                >
                                    {crop.name}
                                </MenuItem>
                            ))}
                        </Menu>
                        <TextField 
                            label='Variety' 
                            variant="outlined"
                            InputLabelProps={{shrink: true}}
                            value={field.variety}
                            disabled={!(field["crop"] !== "")}
                            onClick={e => setAnchorElVariety(e.currentTarget)}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                        <Menu
                            anchorEl={anchorElVariety}
                            open={openVarietyMenu}
                            onClose={() => setAnchorElVariety(null)}
                            style={{  marginTop: 50, maxHeight: 300 }}
                        >
                            {varietiesArray.map(variety => (
                                <MenuItem
                                    key={`field-creation-variety-${variety.id}`}
                                    id={variety.id}
                                    value={variety.name}
                                    style={{ width: 250 }}
                                    onClick={(e) => handleMenuItemSelection("variety", e) }
                                >
                                    {variety.name}
                                </MenuItem>
                            ))}
                        </Menu>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", gap: 15, width: "100%" }}>
                        <TextField 
                            type="text" 
                            label='Region' 
                            variant="outlined" 
                            InputLabelProps={{shrink: true}}
                            value={field.region}
                            onChange={e => handleInputChange("region", e)}
                        />
                        <TextField 
                            type="date" 
                            label='Planting Date' 
                            variant="outlined" 
                            InputLabelProps={{shrink: true}}
                            value={field.plantingDateP}
                            onChange={e => handleInputChange("plantingDateP", e)}
                        />
                        <Button 
                            variant={field["Shapefile"] ? "contained": "outlined"} 
                            component="label" 
                            color="primary" 
                            style={{ width: 1050 }}
                        >
                            {field["Shapefile"] ? "Change Shapefile" : "Upload Shapefile" }
                            <input type="file" onChange={handleFileChange} hidden />
                        </Button>
                    </div>
                    
                </CardContent>
            </Card>
        </form>
    )
}

export default FieldCreation