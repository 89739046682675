import React, { useState, useEffect } from 'react'

// Modules
import swal from "sweetalert"

// Components
import Page from "src/components/Page"
import LoadingBar from 'src/components/LoadingBar'
import MoveUsers from './MoveUsers'
import MirrorUsers from './MirrorUsers'

// Views

// Context providers / Utils
import networking from "../../../utils/Networking"

// Hooks

// Material-UI *
import { Grid, Container, Button, Card, CardHeader, Divider } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

// Styles
import "./index.css"

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        minHeight: "100%",
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
}))

const ALL_VIEWS = [
    "Overview", "Decadal Risk", "Decadal Var", "Seasonal Risk", "Seasonal Var", 
    "Departure", "Portfolio", "Validation", "Analogs"
]

function ClimateMirrorView() {
    const [state, setState] = useState({
        sourceEnv: "dev",
        destinationEnv: [],
        sourceUsers: null,
        mainAccount: "",
        account: "",
        dataTypes: [],
        views: []
    })
    const [isAllViewsChecked, setAllViewsCheck] = useState(false)
    const [pageView, setPageView] = useState("move")
    const [isLoading, setLoading] = useState(false)
    const [blockUpload, setBlockUpload] = useState(false)

    const classes = useStyles()

    function getUsersByEnv(env) {
        setLoading(true)
        networking
            .get(`/adaptation/${env}/accounts`)
            .then(res => {
                setState({...state, sourceUsers: res.data.data })
                setLoading(false)
            })
            .catch(err => {
                console.log(err)
                swal("Error!", `Couldn't get the users for ${env}`, "error")
                setLoading(false)
            })
    }

    function handleSourceEnvChange(e) {
        let value = e.target.value
        setState({...state, sourceEnv: value })
    }

    function handleDestinationEnvChange(e) {
        let value = e.target.value
        setState({ ...state, destinationEnv: value })
    }

    function handleSourceUserChange(e, value) {
        const mainAccount = value ? value.email : ""
        setState({ ...state, mainAccount })
    }

    function handleDestinationUserChange(e, value) {
        const account = value ? value.email : ""
        setState({ ...state, account })
    }

    function handleDataTypeChange(value) {
        if (state.dataTypes.includes(value)) {
            // Removes dataType value
            if (value === "Cache") {
                setState({ ...state, dataTypes: state.dataTypes.filter(item => item !== value), views: [] })
            } else {
            setState({ ...state, dataTypes: state.dataTypes.filter(item => item !== value) })
            }
        } else {
            // Adds dataType value
            let dataTypesArr = [...state.dataTypes]
            dataTypesArr.push(value)
            setState({ ...state, dataTypes: dataTypesArr })
        }
    }

    function handleViewChange(e) {
        let value = e.target.value
        if (state.views.includes(value)) {
            // Removes view value
            setState({ ...state, views: state.views.filter(item => item !== value) })
        } else {
            // Adds view value
            let viewsArr = [...state.views]
            viewsArr.push(value)
            setState({ ...state, views: viewsArr })
        }
    }

    function handleAllViewsChange(e) {
        let checkedStatus = e.target.checked
        setAllViewsCheck(checkedStatus)
        if (checkedStatus) setState({ ...state, views: ALL_VIEWS })
        else setState({ ...state, views: [] })
    }

    function getSourceUsers() {
        getUsersByEnv(state.sourceEnv)
    }

    function handleMoveData() {
        setLoading(true)
        setBlockUpload(true)
        networking
            .post("/adaptation/data_transfer", {
                "source_env": state.sourceEnv,
                "destination_envs": state.destinationEnv,
                "account": state.mainAccount,
                "data-types": state.dataTypes,
                "views": state.views
            })
            .then(res => {
                if (res.status === 200) {
                    console.log(res)
                    setState({
                        sourceEnv: "dev",
                        destinationEnv: [],
                        sourceUsers: null,
                        dataTypes: [],
                        views: []
                    })
                    swal("Success!","Data move process done successfully, this could take some time to finish", "success")
                    setLoading(false)
                    setBlockUpload(false)
                } else {
                    console.log(res)
                    swal("Error!", "Data move process could not be done", "error")
                    setLoading(false)
                    setBlockUpload(false)
                }
            })
            .catch(err => {
                console.log(err)
                swal("Error!", `${err}`, "error")
                setLoading(false)
                setBlockUpload(false)
            })
    }

    function handleMirrorData() {
        setLoading(true)
        setBlockUpload(true)
        networking
            .post(`/adaptation/${state.sourceEnv}/main_accounts`, {
                "main_account": state.mainAccount,
                "account": state.account
            })
            .then(res => {
                if (res.status === 200) {
                    console.log(res)
                    setState({
                        mainAccount: null,
                        account: null,
                    })
                    swal("Success!","Data mirror process done successfully, this could take some time to finish", "success")
                    setLoading(false)
                    setBlockUpload(false)
                } else {
                    console.log(res)
                    swal("Error!", "Data mirror process could not be done", "error")
                    setLoading(false)
                    setBlockUpload(false)
                }
            })
            .catch(err => {
                console.log(err)
                swal("Error!", `${err}`, "error")
                setLoading(false)
                setBlockUpload(false)
            })
    }

    function handleBlockUpload() {
        if (pageView === "move") {
            if (state.mainAccount && state.destinationEnv.length !== 0 &&
            (state.dataTypes.length !== 0 && !state.dataTypes.includes("Cache") || 
            state.dataTypes.includes("Cache") && state.views.length !== 0)) {
                setBlockUpload(false)
            } else setBlockUpload(true)
        } else if (pageView === "mirror") {
            if (state.mainAccount && state.account) {
                setBlockUpload(false)
            } else setBlockUpload(true)
        }
    }

    useEffect(() => {
        handleBlockUpload()
    }, [state, pageView])

    return(
        <Page className={classes.root} title="Copy Users">
            <Container maxWidth="lg">
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                    <Card>
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <CardHeader title={`${pageView === "move" ? "Move": "Mirror"} User Data`} />
                            <div style={{ display: "flex", alignContent: "center", gap: 15, margin: 10 }}>
                                <Button
                                    variant="contained"
                                    color={pageView === "move" ? "primary": "default"}
                                    onClick={() => setPageView("move")}
                                >
                                    Move
                                </Button>
                                <Button
                                    variant="contained"
                                    color={pageView === "move" ? "default": "primary"}
                                    onClick={() => setPageView("mirror")}
                                >
                                    Mirror
                                </Button>
                            </div>
                        </div>
                        <Divider />
                        {isLoading && <LoadingBar />}
                        {pageView === "move" ? (
                        <MoveUsers
                            sourceEnv={state.sourceEnv}
                            getUsers={getSourceUsers}
                            sourceUsers={state.sourceUsers}
                            selectedSourceUser={state.mainAccount}
                            destinationEnv={state.destinationEnv}
                            dataTypes={state.dataTypes}
                            views={state.views}
                            onSourceEnvChange={handleSourceEnvChange}
                            onDestinationEnvChange={handleDestinationEnvChange}
                            onSourceUserChange={handleSourceUserChange}
                            onDataTypeChange={handleDataTypeChange}
                            onViewChange={handleViewChange}
                            onAllViewsChange={handleAllViewsChange}
                            isAllViewsChecked={isAllViewsChecked}
                            onMoveData={handleMoveData}
                            blockUpload={blockUpload}
                        />) : (
                        <MirrorUsers 
                            sourceEnv={state.sourceEnv}
                            onSourceEnvChange={handleSourceEnvChange}
                            getUsers={getSourceUsers}
                            sourceUsers={state.sourceUsers}
                            selectedSourceUser={state.mainAccount}
                            selectedDestinationUser={state.account}
                            onSourceUserChange={handleSourceUserChange}
                            onDestinationUserChange={handleDestinationUserChange}
                            onMirrorData={handleMirrorData}
                            blockUpload={blockUpload}
                        />)
                        }
                    </Card>
                    </Grid>
                </Grid>
            </Container>
        </Page>
    )
}

export default ClimateMirrorView
