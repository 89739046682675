import React, { useState } from "react"

// Modules
import PropTypes from "prop-types"
import clsx from "clsx"

// Components
import ConfirmDialog from "../../../../components/ConfirmDialog"
import DangerButton from "../../../../components/DangerButton"

// Views

// Context providers / Utils
import networking from "../../../../utils/Networking"

// Hooks

// Material-UI *
import { Box, Card, CardActions, CardContent, Divider, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import FaceIcon from "@mui/icons-material/Face"
import BusinessIcon from "@mui/icons-material/Business"

// Styles
import "./user-data.css"

const useStyles = makeStyles(() => ({
    root: {},
    avatar: {
        height: 100,
        width: 100,
    },
}))

const Data = props => {
    const classes = useStyles()
    const { userData, onDeleteUser = () => {}, className, ...rest } = props
    const [openDeleteModal, setOpenDeleteModal] = useState(false)

    return (
        <div>
            <Card className={clsx(classes.root, className)} {...rest}>
                <CardContent>
                    <Box alignItems="center" display="flex" flexDirection="column">
                        {/* TODO: Uncomment when users set image <Avatar className={classes.avatar} src={user.avatar} />*/}
                        <div className="user-initial">{"X"}</div>
                        <Typography color="textPrimary" gutterBottom variant="h3">
                            {userData?.email || "None"}
                        </Typography>
                        <Typography className="data-container" color="textSecondary" variant="body1">
                            <FaceIcon fontSize="small" />
                            {userData?.name || "None"}
                        </Typography>
                        <Typography className="data-container" color="textSecondary" variant="body1">
                            <BusinessIcon fontSize="small" />
                            {userData?.company || "None"}
                        </Typography>
                    </Box>
                </CardContent>
                <Divider />
                <CardActions>
                    <DangerButton
                        onClick={() => {
                            setOpenDeleteModal(true)
                        }}
                        text="Delete user"
                        id={"deleteBtn"}
                    />
                </CardActions>
            </Card>

            <ConfirmDialog
                title={"Do you want to delete this user from " + networking.getEnv() + "?"}
                confirmClass="danger"
                confirmText="Delete"
                open={openDeleteModal}
                setOpen={setOpenDeleteModal}
                onConfirm={() => {
                    onDeleteUser()
                }}
            ></ConfirmDialog>
        </div>
    )
}

Data.propTypes = {
    className: PropTypes.string,
}

export default Data
