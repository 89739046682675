import React from "react"
import { ThemeProvider, StyledEngineProvider, createTheme, adaptV4Theme } from "@mui/material/styles";
import Button from "@mui/material/Button"
import { red } from "@mui/material/colors"

const redTheme = createTheme(adaptV4Theme({ palette: { primary: red }}))

const DangerButton = props => {
    const { id, text, variant = "text", onClick } = props
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={redTheme}>
                <Button fullWidth color="primary" variant={variant} onClick={onClick} id={id}>
                    {text}
                </Button>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default DangerButton
