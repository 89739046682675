import React, { useEffect, useState } from "react"
import app from "../utils/Fire.js"
import LoadingScreen from "../components/LoadingScreen"

export const AuthContext = React.createContext(null)

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null)
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [pending, setPending] = useState(true)

    useEffect(() => {
        app.auth().onAuthStateChanged(user => {
            if (user !== null) {
                setCurrentUser(user)
                setIsLoggedIn(true)
                window['wrapper_pendo'](user.email)
            } else {
                setIsLoggedIn(false)
            }
            setPending(false)
        })
    }, [])

    if (pending) {
        return <LoadingScreen />
    }

    return (
        <AuthContext.Provider
            value={{
                currentUser,
                isLoggedIn,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}
