import React, { useState } from "react"

// Modules
import clsx from "clsx"
import PropTypes from "prop-types"
import swal from "sweetalert"

// Components
import AddLocations from "./AddLocations"

// Views

// Context providers / Utils

// Hooks

// Material-UI *
import { Box, Button } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

// Styles

const useStyles = makeStyles(theme => ({
    root: {},
    importButton: {
        marginRight: theme.spacing(1),
    },
    exportButton: {
        marginRight: theme.spacing(1),
    },
}))

const Toolbar = props => {
    const classes = useStyles()
    const { className, onRefresh, onAddLocations = () => {}, ...rest } = props

    const [openModal, setOpenModal] = useState(false)
    const [formError, setFormError] = useState("")

    function closeModal() {
        setOpenModal(false)
    }

    function handleAddLocation(locationData) {
        setFormError("")
        locationData.coordinates = eval(locationData.coordinates)
        onAddLocations(locationData).then(success => {
            if (success) {
                swal("Success!", "Locations added!", "success")
                setOpenModal(false)
                onRefresh()
            }
        })
    }

    return (
        <div className={clsx(classes.root, className)} {...rest}>
            <Box display="flex" justifyContent="flex-end">
                <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                        setOpenModal(true)
                    }}
                    id="addMultipleLocationsBtn"
                >
                    Add Multiple Locations
                </Button>
            </Box>
            <AddLocations
                isOpen={openModal}
                closeModal={closeModal}
                onAddLocations={handleAddLocation}
                formError={formError}
            />
        </div>
    )
}

Toolbar.propTypes = {
    className: PropTypes.string,
}

export default Toolbar
