import React, { useEffect, useState } from "react"

// Modules
import { useLocation } from "react-router-dom"
import PropTypes from "prop-types"
import {
    User as UserIcon,
    Users as UsersIcon,
    MapPin as MapPinIcon,
    BarChart2 as BarChart2Icon,
    //AlertCircle as AlertCircleIcon,
    //Lock as LockIcon,
    //Settings as SettingsIcon,
    //ShoppingBag as ShoppingBagIcon,
    //UserPlus as UserPlusIcon,
} from "react-feather"

// Components
import NavItem from "./NavItem"

// Views
// Context providers / Utils
import app from "../../../utils/Fire"

// Hooks

// Material-UI *
import { Avatar, Box, Divider, Drawer, Hidden, List, Typography } from "@mui/material"

import makeStyles from "@mui/styles/makeStyles"

// Styles

const items = [
    {
        href: "/app/users",
        icon: UserIcon,
        title: "Users",
    },
    {
        href: "/app/tools/copy-user",
        icon: UsersIcon,
        title: "Copy Users",
    },
    {
        href: "/app/locations",
        icon: MapPinIcon,
        title: "Locations",
    },
    {
        href: "/app/custom-reports",
        icon: BarChart2Icon,
        title: "Custom Reports",
    },
    /*{
    href: '/app/products',
    icon: ShoppingBagIcon,
    title: 'Products'
  },
  {
    href: '/app/account',
    icon: UserIcon,
    title: 'Account'
  },
  {
    href: '/app/settings',
    icon: SettingsIcon,
    title: 'Settings'
  },
  {
    href: '/login',
    icon: LockIcon,
    title: 'Login'
  },
  {
    href: '/register',
    icon: UserPlusIcon,
    title: 'Register'
  },
  {
    href: '/404',
    icon: AlertCircleIcon,
    title: 'Error'
  }*/
]

const useStyles = makeStyles(() => ({
    mobileDrawer: {
        width: 256,
    },
    desktopDrawer: {
        width: 256,
        top: 64,
        height: "calc(100% - 64px)",
    },
    avatar: {
        cursor: "pointer",
        width: 64,
        height: 64,
    },
}))

const NavBar = ({ onMobileClose, openMobile }) => {
    const classes = useStyles()
    const location = useLocation()
    const [user, setUser] = useState({
        avatar: "/static/images/avatars/avatar_4.png",
        jobTitle: "Admin Dashboard",
        name: "",
    })

    async function getUser() {
        let us = await app.auth().currentUser
        setUser({ ...user, name: us.email })
    }

    useEffect(() => {
        if (openMobile && onMobileClose) {
            onMobileClose()
        }
        getUser()
    }, [location.pathname])

    const content = (
        <Box height="100%" display="flex" flexDirection="column">
            <Box alignItems="center" display="flex" flexDirection="column" p={2}>
                <Avatar className={classes.avatar} src={user.avatar} />
                <Typography className={classes.name} color="textPrimary" variant="h5">
                    {user.name}
                </Typography>
                <Typography color="textSecondary" variant="body2">
                    {user.jobTitle}
                </Typography>
            </Box>
            <Divider />
            <Box p={2}>
                <List>
                    {items.map(item => (
                        <NavItem href={item.href} key={item.title} title={item.title} icon={item.icon} />
                    ))}
                </List>
            </Box>
            <Box flexGrow={1} />
        </Box>
    )

    return (
        <>
            <Hidden lgUp>
                <Drawer
                    anchor="left"
                    classes={{ paper: classes.mobileDrawer }}
                    onClose={onMobileClose}
                    open={openMobile}
                    variant="temporary"
                >
                    {content}
                </Drawer>
            </Hidden>
            <Hidden lgDown>
                <Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open variant="persistent">
                    {content}
                </Drawer>
            </Hidden>
        </>
    )
}

NavBar.propTypes = {
    onMobileClose: PropTypes.func,
    openMobile: PropTypes.bool,
}

NavBar.defaultProps = {
    onMobileClose: () => {},
    openMobile: false,
}

export default NavBar
