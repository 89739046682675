import React from "react"
import { Navigate } from "react-router-dom"
import DashboardLayout from "src/layouts/DashboardLayout"
import MainLayout from "src/layouts/MainLayout"
import UsersView from "src/views/users/UsersView"
import UserProfile from "src/views/users/UserProfile/UserProfile"
import LocationsView from "src/views/locations"
import CustomReportsView from "src/views/reports/CustomReportsView/CustomReportsView"
import NotFoundView from "src/views/errors/NotFoundView"
import LoginView from "src/views/auth/LoginView"
import CopyUserView from "src/views/tools/CopyUserView"
import CopyToolView from "./views/tools/CopyToolView"

// For further use
/*import AccountView from "src/views/account/AccountView"
import ProductListView from "src/views/product/ProductListView"
import RegisterView from "src/views/auth/RegisterView"
import SettingsView from "src/views/settings/SettingsView"*/

const routes = isLoggedIn => [
    {
        path: "app",
        element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />,
        children: [
            { path: "users", element: <UsersView /> },
            { path: "users/:uid", element: <UserProfile /> },
            { path: "locations/", element: <LocationsView /> },
            { path: "custom-reports/", element: <CustomReportsView /> },
            { path: "tools/copy-user", element: <CopyToolView /> },
            { path: "*", element: <Navigate to="/404" /> },
        ],
    },
    {
        path: "/",
        element: !isLoggedIn ? <MainLayout /> : <Navigate to="/app/users" />,
        children: [
            { path: "/", element: <LoginView /> },
            { path: "/login", element: <LoginView /> },
            { path: "404", element: <NotFoundView /> },
            { path: "*", element: <Navigate to="/404" /> },
            //{ path: "register", element: <RegisterView /> },
            ///{ path: "/", element: <Navigate to="/app/dashboard" /> },
        ],
    },
]

export default routes
