import React from "react"
import { useNavigate } from "react-router-dom"
import * as Yup from "yup"
import { Formik } from "formik"
import { Box, Button, Container, TextField, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import app from "../../utils/Fire"
import Page from "src/components/Page"

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.dark,
        height: "100%",
        paddingBottom: theme.spacing(3),
        paddingTop: theme.spacing(3),
    },
}))

const LoginView = () => {
    const classes = useStyles()
    const navigate = useNavigate()

    async function handleLogin(event) {
        event.preventDefault()

        const { email, password } = event.target.elements
        try {
            await app.auth().signInWithEmailAndPassword(email.value, password.value)
            navigate("/app/users/")
        } catch (error) {
            alert(error)
        }
    }

    return (
        <Page className={classes.root} title="Admin Dashboard">
            <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
                <Container maxWidth="sm">
                    <Formik
                        initialValues={{
                            email: "",
                            password: "",
                        }}
                        validationSchema={Yup.object().shape({
                            email: Yup.string()
                                .email("Must be a valid email")
                                .max(255)
                                .required("Email is required"),
                            password: Yup.string()
                                .max(255)
                                .required("Password is required"),
                        })}
                        onSubmit={() => {
                            navigate("/app/dashboard", { replace: true })
                        }}
                    >
                        {({ errors, handleBlur, handleChange, isSubmitting, touched, values }) => (
                            <form onSubmit={handleLogin}>
                                <Box mb={3}>
                                    <Typography color="textPrimary" variant="h2">
                                        Log in
                                    </Typography>
                                    <Typography color="textSecondary" gutterBottom variant="body2">
                                        Log in to the admin dashboard
                                    </Typography>
                                </Box>
                                <TextField
                                    error={Boolean(touched.email && errors.email)}
                                    fullWidth
                                    helperText={touched.email && errors.email}
                                    label="Email Address"
                                    margin="normal"
                                    name="email"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="email"
                                    value={values.email}
                                    variant="outlined"
                                    id="email"
                                />
                                <TextField
                                    error={Boolean(touched.password && errors.password)}
                                    fullWidth
                                    helperText={touched.password && errors.password}
                                    label="Password"
                                    margin="normal"
                                    name="password"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    type="password"
                                    value={values.password}
                                    variant="outlined"
                                    id="password"
                                />
                                <Box my={2}>
                                    <Button
                                        color="primary"
                                        disabled={isSubmitting}
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        id="loginBtn"
                                    >
                                        Login
                                    </Button>
                                </Box>
                            </form>
                        )}
                    </Formik>
                </Container>
            </Box>
        </Page>
    )
}

export default LoginView
