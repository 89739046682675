import React, { useState, useEffect } from 'react'
// Modules
import clsx from "clsx"

// Material UI
import { Card, CardContent, CardHeader, Divider, TextField, Button } from "@mui/material";

import makeStyles from '@mui/styles/makeStyles';

// Styles
const useStyles = makeStyles({
    root: {
        padding: "32px 18px",
        paddingBottom: 0,
        width: "100%",
    },
    button: {
        margin: "15px"
    },
    cardContent: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 20,
    },
})

const initialCropState = {
    crop: "",
    variety: "",
}

const CropCreation = ({ setCreatedCrop }) => {
    const [crop, setCrop] = useState(initialCropState)
    const [buttonDisabled, setButtonDisabled] = useState(false)
    
    const classes = useStyles()

    function handleInputChange (propName, e) {
        let targetText = e.target.value
        setCrop({...crop, [propName] : targetText})
    }

    function checkFields() {
        if ("crop" in crop  && "variety" in crop && crop["crop"] !== "" && crop["variety"] !== "" ) {
            setButtonDisabled(false)
        } else {
            setButtonDisabled(true)
        }
    }

    function handleSaveButton() {
        setCreatedCrop(crop)
        setCrop(initialCropState)
    }

    useEffect(() => {
        checkFields()
    }, [crop])

    return (
        <form className={clsx(classes.root)}>
            <Card className={classes.card}>
                <div style={{display: "flex", justifyContent: "space-between"}}>
                    <CardHeader title="Crop and Variety Creation" subheader="Create crops and varieties for the user" />
                    <Button 
                        className={classes.button} 
                        color="primary" 
                        variant="contained"
                        onClick={() => handleSaveButton()}
                        disabled={buttonDisabled}
                    >
                        Save
                    </Button>
                </div>
                <Divider />
                <CardContent className={classes.cardContent}>
                    <div style={{ display: "flex", justifyContent: "space-between", gap: 15, width: "100%" }}>
                        <TextField 
                            type="text" 
                            label='Crop' 
                            variant="outlined" 
                            InputLabelProps={{shrink: true}}
                            value={crop.crop}
                            onChange={e => handleInputChange("crop", e)}
                        />
                        <TextField 
                            type="text" 
                            label='Variety' 
                            variant="outlined" 
                            InputLabelProps={{shrink: true}}
                            value={crop.variety}
                            onChange={e => handleInputChange("variety", e)}
                        />
                    </div>
                </CardContent>
            </Card>
        </form>
    )
}

export default CropCreation