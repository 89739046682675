import React from 'react'

import FieldEditionItem from './FieldEditionItem'

import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    FormControl,
    FormHelperText,
    Select,
    MenuItem,
    TextField,
    Button,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { FirstPage, LastPage, ChevronLeft, ChevronRight } from "@mui/icons-material"


// Styles
const useStyles = makeStyles({
    root: {
        padding: "32px 18px",
        paddingTop: 0,
        width: "100%",
    },
    button: {
        margin: "15px"
    },
    cardContent: {
        display: "flex",
        alignItems: "center",
        gap: 20,
    },
    icon: {
        fontSize: 40,
        cursor: "pointer",
    },
})

function FieldEdition(props) {
    const { 
        fields, 
        crops, 
        currentPage, 
        rows, 
        rowsList, 
        handlePageChange, 
        dataLength, 
        handleRowChange, 
        handleFieldDelete, 
        handleFieldEdit,
        handleFieldSearch, 
        handleFieldSearchClear
    } = props
    const classes = useStyles()

    let _fields = [...fields]

    let _rows = String(rows).includes("all") ? fields.length : rows

	let shownFields = _fields.slice(_rows * currentPage - _rows, _rows * currentPage)

    function clearSearchValue() {
        document.getElementById("field-search-input").value = ""
        handleFieldSearchClear()
    }

    return (
        <form className={classes.root}>
            <Card className={classes.card}>
                <Divider />
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <CardHeader title="Field Edition" subheader="Edit fields for the user" style={{ width: 250 }} />
                    <div style={{display: "flex" }}>
                        <div style={{ display: "flex", justifyContent: "center", marginTop: 30}}>
                            <FirstPage className={classes.icon} onClick={() => handlePageChange(1)} />
                            <ChevronLeft className={classes.icon} onClick={() => handlePageChange(currentPage - 1 <= 0 ? currentPage : currentPage - 1)} />
                            <label style={{ marginTop: 10 }}>
                                {dataLength > 0 ? currentPage * _rows - (_rows - 1) : 0} -{" "}
                                {currentPage * _rows > dataLength ? dataLength : currentPage * _rows} of {dataLength}
                            </label>
                            <ChevronRight className={classes.icon} onClick={() => handlePageChange((currentPage + 1) * rows - rows < dataLength ? currentPage + 1 : currentPage)} />
                            <LastPage className={classes.icon} onClick={() => handlePageChange(Math.max(Math.ceil(dataLength / rows),1))} />
                            <FormControl style={{ marginLeft: 20, width: 100 }}>
                                <Select id="rows-select" value={rows} label={rows}>
                                    {rowsList.map(rowOption => {
                                        return (
                                            <MenuItem value={rowOption} onClick={() => handleRowChange(rowOption)} >
                                                {rowOption}
                                            </MenuItem>
                                        )
                                    })}
                                </Select>
                                <FormHelperText>Rows per page</FormHelperText>
                            </FormControl>
                        </div>
                        <TextField 
                            id="field-search-input"
                            type="search" 
                            label="Search for field" 
                            variant="outlined" 
                            onChange={(e) => handleFieldSearch(e.target.value)}
                            style={{ width: 250, margin: 20 }} 
                        />
                        <Button
                            variant="outlined"
                            color="primary"
                            style={{ width: 100, margin: 20, marginLeft: 0 }}
                            onClick={() => clearSearchValue()}
                        >
                            Clear Search
                        </Button>
                    </div>
                </div>
                <Divider />
                <CardContent style={{ paddingBottom: 0 }} >
                    {Object.keys(fields).length !== 0 && (
                        < >
                            {shownFields.map((field, i) => (
                                <FieldEditionItem 
                                    field={field}
                                    crops={crops}
                                    handleFieldDelete={handleFieldDelete}
                                    handleFieldEdit={handleFieldEdit}
                                />
                            ))}
                        </>
                    )}
                </CardContent>
        </Card>
        </form>
    )
}

export default FieldEdition