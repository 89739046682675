import { useMemo } from "react"
/**
 * Generator function to flatten the list of users
 */
const flattenUsers = function* (rawUsers) {
    // Convert to array of (key,value)
    const users = Object.entries(rawUsers)
    for (var user of users) {
        yield {email: user[0], parent: user[0]}
        for (var subuser of user[1]) {
            yield {email: subuser, parent: user[0]}
        }
    }
}

export function useFlatUsers(users) {
    return useMemo(() => {
        if (users) return [...flattenUsers(users)]
        return []
    }, [users])
}
